import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { bookkeeping } from "./bookkeeping.reducer";
import { webfaceState } from "./webface.state.reducer";
import { loadingAPI } from "./loading.API.reducer";
import { billing } from "./billing.reducer";

const rootReducer = combineReducers({
	authentication,
	registration,
	users,
	bookkeeping,
	alert,
	webfaceState,
	loadingAPI,
	billing
});

export default rootReducer;
