const config = {
	apiUrl: "/api",
	clientUrls: {
		HOME: "/",
		HOME_FEATURES: "/#benefits",
		HOME_FOUNDERS: "/#founders",
		HOME_PRICING: "/#pricing",

		LOGIN: "/login",
		REGISTER: "/register",
		COMPLETE_SIGNUP: "/complete_signup",
		FORGOT: "/service/auth/forgot",
		RESET: "/service/auth/reset/:token_id",
		VERIFY_EMAIL: "/service/auth/verifyEmail/:token_id",
		CONNECT: "/connect",

		HELP: "/service/help",

		QBO_CALLBACK: "/qbo/callback",
		QBO_LEARN_MORE: "/qbo/learn_more",
		QBO_DISCONNECTED: "/qbo/disconnected",
		XERO_CALLBACK: "/xero/callback",
		XERO__SIGN_IN_CALLBACK: "/xero/signin_callback",
		SUBSCRIPTION: "/subscription",
		SUBSCRIPTION_PAYMENT: "/billing/payment",
		USER_ACCOUNT_SUMMARY: "/account",
		COMPLETE_REGISTRATION: "/billing/complete_registration",
		PREPAID_EXPENSES_MODULE: (company_id) =>
			`/companies/${company_id}/prepaid_expenses`,
		ACCRUED_LIABILITIES_MODULE: (company_id) =>
			`/companies/${company_id}/accrued_liabilities`,

		MOKAFILE__COMPANY_HOME: (company_id) =>
			company_id ? `/companies/${company_id}` : `/companies`,

		MOKAFILE__LIST_COMPANIES: "/companies/list",
		MOKAFILE__NEW_COMPANY: "/companies/new_service",
		MOKAFILE__COMPANY__HELP: "/companies/help",

		MOKAFILE__NETWORK__PROBLEM: "/problems",

		POLICY: "/policy",
		EULA: "/eula",
		TERMS: "/terms",

		GUIDE_TEST: "/guide/test",
	},
	adminUrls: {
		HOME: "/admin",
	},

	billing: {
		STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PUBLIC_KEY,
	},
};

config.NAVBAR = {
	getServices: (companyId) => [
		{
			title: "Prepaid Expenses",
			url: config.clientUrls.PREPAID_EXPENSES_MODULE(companyId),
		},
		{
			title: "Accrued Liabilities",
			url: config.clientUrls.ACCRUED_LIABILITIES_MODULE(companyId),
		},
		// ADD NEW SERVICES HERE
	],
};

config.TRIAL_DURATION = 45;
config.DAYS_BEFORE_ACCOUNT_LOCK = 14;
config.REGISTRATION_OFF = (process.env.REGISTRATION_OFF || process.env.REACT_APP_REGISTRATION_OFF || "false").toLowerCase() === "true";
config.ALLOW_US_REGISTRATION = (process.env.ALLOW_US_REGISTRATION || process.env.REACT_APP_ALLOW_US_REGISTRATION || "false").toLowerCase() === "true";
config.ALLOW_AU_REGISTRATION = (process.env.ALLOW_AU_REGISTRATION || process.env.REACT_APP_ALLOW_AU_REGISTRATION || "false").toLowerCase() === "true";
config.ALLOW_NZ_REGISTRATION = (process.env.ALLOW_NZ_REGISTRATION || process.env.REACT_APP_ALLOW_NZ_REGISTRATION || "false").toLowerCase() === "true";

// In Minutes
config.IDDLE_SESSION_LOGOUT_TIMER = parseInt(process.env.IDDLE_SESSION_LOGOUT_TIMER || process.env.REACT_APP_IDDLE_SESSION_LOGOUT_TIMER || "1");

config.STAGE_ENDPOINT = process.env.STAGE_ENDPOINT || "stage.kalestack.com";
config.PRODUCTION_ENDPOINT = process.env.PRODUCTION_ENDPOINT || "kalestack.com";
config.GCP_APP_SPOT = "appspot.com";
export default config;
