// source: https://edisondevadoss.medium.com/how-to-use-redux-persist-in-react-application-35943c1d8292
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from "_reducers";

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['authentication'] // which reducer want to store
};

const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = [
  thunkMiddleware,
  process.env.NODE_ENV !== 'production' && require("redux-logger").createLogger()
].filter(Boolean);

const store = createStore(
  pReducer,
  applyMiddleware(...middleware)
);

const persistor = persistStore(store);

export { persistor, store };