import { billingConstants } from "_constants";

function flagConstantGenerator(flagConstant) {
	return {
		loading: `loading_${flagConstant}`,
		loaded: `loaded_${flagConstant}`,
	};
}

const subscribeAndChargeFlags = flagConstantGenerator(
	billingConstants.SUBSCRIBE_AND_CHARGE
);

const modifySubscription = flagConstantGenerator(
	billingConstants.MODIFY_SUBSCRIPTION
);

const cancelSubscription = flagConstantGenerator(
	billingConstants.CANCEL_SUBSCRIPTION
);

const getUserAccountSummaryFlags = flagConstantGenerator(
	billingConstants.GET_USER_ACCOUNT_SUMMARY
);

const getUserAccountSummaryPaymentsFlags = flagConstantGenerator(
	billingConstants.GET_USER_ACCOUNT_SUMMARY_PAYMENTS
);

export const billingFlagConstants = {
	subscribeAndChargeFlags,
	modifySubscription,
	cancelSubscription,
	getUserAccountSummaryFlags,
	getUserAccountSummaryPaymentsFlags,
};
