import Select from "react-select";
import { FlagIconMappingObject } from "./Icon";

export const CurrencySelector = ({ curCurrency, currencies = [], onCurrencyChange, ...props }) => {
  if (!curCurrency && (!currencies || currencies.length < 1)) return <span></span>;
  if (curCurrency && currencies.length < 2)
    return <span className="my-auto">
      {FlagIconMappingObject(curCurrency)} {curCurrency.toUpperCase()}
    </span>;
  const options = currencies.map(crncy => (
    { value: crncy, label: <>{FlagIconMappingObject(crncy)} {crncy.toUpperCase()}</> }
  ));

  return (
    <Select
      // defaultValue={options.find(o => o.value === curCurrency)}
      value={options.find(o => o.value === curCurrency)}
      onChange={(_selectedOption) => {
        if (onCurrencyChange) onCurrencyChange(_selectedOption.value);
      }}
      options={options} />
  )
}