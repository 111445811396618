export const ALBookkeepingConstants = {
	SET_AL_ACCOUNT: "SET_AL_ACCOUNT",

	SET_AL_ACCOUNT_REQUEST: "SET_AL_ACCOUNT_REQUEST",
	SET_AL_ACCOUNT_SUCCESS: "SET_AL_ACCOUNT_SUCCESS",
	SET_AL_ACCOUNT_FAILURE: "SET_AL_ACCOUNT_FAILURE",

	ADD_AL: "ADD_AL",

	ADD_AL_REQUEST: "ADD_AL_REQUEST",
	ADD_AL_SUCCESS: "ADD_AL_SUCCESS",
	ADD_AL_FAILURE: "ADD_AL_FAILURE",

	DELETE_AL_SCHEDULE: "DELETE_AL_SCHEDULE",

	DELETE_AL_SCHEDULE_REQUEST: "DELETE_AL_SCHEDULE_REQUEST",
	DELETE_AL_SCHEDULE_SUCCESS: "DELETE_AL_SCHEDULE_SUCCESS",
	DELETE_AL_SCHEDULE_FAILURE: "DELETE_AL_SCHEDULE_FAILURE",

	GET_AL_LIST: "GET_AL_LIST",

	GET_AL_LIST_REQUEST: "GET_AL_LIST_REQUEST",
	GET_AL_LIST_SUCCESS: "GET_AL_LIST_SUCCESS",
	GET_AL_LIST_FAILURE: "GET_AL_LIST_FAILURE",

	EXPORT_AL_LIST: "EXPORT_AL_LIST",

	EXPORT_AL_LIST_REQUEST: "EXPORT_AL_LIST_REQUEST",
	EXPORT_AL_LIST_SUCCESS: "EXPORT_AL_LIST_SUCCESS",
	EXPORT_AL_LIST_FAILURE: "EXPORT_AL_LIST_FAILURE",

	GET_AL_SCHEDULE: "GET_AL_SCHEDULE",

	GET_AL_SCHEDULE_REQUEST: "GET_AL_SCHEDULE_REQUEST",
	GET_AL_SCHEDULE_SUCCESS: "GET_AL_SCHEDULE_SUCCESS",
	GET_AL_SCHEDULE_FAILURE: "GET_AL_SCHEDULE_FAILURE",

	GET_AL_DATA_TO_BE_RECOGNIZED: "GET_AL_DATA_TO_BE_RECOGNIZED",

	GET_AL_DATA_TO_BE_RECOGNIZED_REQUEST: "GET_AL_DATA_TO_BE_RECOGNIZED_REQUEST",
	GET_AL_DATA_TO_BE_RECOGNIZED_SUCCESS: "GET_AL_DATA_TO_BE_RECOGNIZED_SUCCESS",
	GET_AL_DATA_TO_BE_RECOGNIZED_FAILURE: "GET_AL_DATA_TO_BE_RECOGNIZED_FAILURE",

	CONFIRM_RECOGNIZE_AL_DATA: "CONFIRM_RECOGNIZE_AL_DATA",

	CONFIRM_RECOGNIZE_AL_DATA_REQUEST: "CONFIRM_RECOGNIZE_AL_DATA_REQUEST",
	CONFIRM_RECOGNIZE_AL_DATA_SUCCESS: "CONFIRM_RECOGNIZE_AL_DATA_SUCCESS",
	CONFIRM_RECOGNIZE_AL_DATA_FAILURE: "CONFIRM_RECOGNIZE_AL_DATA_FAILURE",

	ROLLBACK_RECOGNIZE_AL_DATA: "ROLLBACK_RECOGNIZE_AL_DATA",

	ROLLBACK_RECOGNIZE_AL_DATA_REQUEST: "ROLLBACK_RECOGNIZE_AL_DATA_REQUEST",
	ROLLBACK_RECOGNIZE_AL_DATA_SUCCESS: "ROLLBACK_RECOGNIZE_AL_DATA_SUCCESS",
	ROLLBACK_RECOGNIZE_AL_DATA_FAILURE: "ROLLBACK_RECOGNIZE_AL_DATA_FAILURE",

	DELETE_ALS_INLINE: "DELETE_ALS_INLINE",
};
