import React from "react";
import { Dropdown, DropdownButton, ButtonGroup } from "_components/Base";

export const MyDropdownButton = ({ items, title, className, variant }) => {
    if (!items || items.length < 1) {
        return <></>;
    }

    return (
        <DropdownButton
            as={ButtonGroup}
            id="dropdown-button-basic"
            variant={variant ? variant : "outline-secondary"}
            title={title}
            className={className ? className : ""}
        >
            {items.map((item, indx) => (
                <Dropdown.Item key={`dropdown-option-${indx}`}>
                    <span onClick={item.onClick}>{item.name}</span>
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
};
