import React from "react";
import { loremIpsum } from "lorem-ipsum";
import { ImageFull, ImageHeader, ImageLeft, ImageRight } from "_components/KnowHow";
import { H1, H2, H3, P1 } from "_components/Base";

export default function KnowHow1(props) {
    return <>
        {/* header with a text */}
        <ImageHeader
            src="https://images.pexels.com/photos/167681/pexels-photo-167681.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        >
            <H1>This is Example KnowHow page 1 (Title on top of image)</H1>
        </ImageHeader>

        <H1>More standard Title</H1>
        {/* paragraph with right side image */}
        <ImageRight width="100px"
            src="https://images.pexels.com/photos/6081495/pexels-photo-6081495.png?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        />
        <p>
            {loremIpsum(
                {
                    count: 50,
                    format: "plain",
                    suffix: "\n",
                    units: "sentences",
                }
            )}
        </p>

        <H2>Sub Title (Bigger than standard text)</H2>
        {/* paragraph with left side image */}
        <ImageLeft height="300px"
            src="https://images.pexels.com/photos/6081495/pexels-photo-6081495.png?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        />
        <P1>
            {loremIpsum(
                {
                    count: 30,
                    format: "plain",
                    suffix: "\n",
                    units: "sentences",
                }
            )}
        </P1>


        <H3>Smaller sub title - Full width image</H3>
        <ImageFull
            src="https://images.pexels.com/photos/167681/pexels-photo-167681.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        />

    </>
}