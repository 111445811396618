import { ALBookkeepingConstants } from "_constants";
import { ALBookkeepingService } from "_services";
import { alertActions, loadingAPIActions } from ".";
import moment from "moment";

export const ALBookkeepingActions = {
    setALAccount,
    addAL,

    deleteALSchedule,

    getALList,
    getALSchedule,
    deleteALsInline,

    getALDataToBeRecognized,
    confirmRecognizeALData,
    rollbackRecognizeALData,

    exportALList,
};

function setALAccount(companyId, id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService.setALAccount(companyId, id).then(
            (result) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return dispatch(success(result.alAccountName));
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return { type: ALBookkeepingConstants.SET_AL_ACCOUNT_REQUEST };
    }
    function success(alAccountName) {
        return {
            type: ALBookkeepingConstants.SET_AL_ACCOUNT_SUCCESS,
            alAccountName,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.SET_AL_ACCOUNT_FAILURE,
            error,
        };
    }
}

function addAL(
    companyId,
    prepaid_expense_data,
    buildOwnSchedule,
    ownSchedule
) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService
            .addAL(
                companyId,
                prepaid_expense_data,
                buildOwnSchedule,
                ownSchedule
            )
            .then(
                (result) => {
                    const schedule = result.schedule,
                        journal = result.journal;
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(journal, schedule));
                    return result;
                },
                (error) => {
                    console.log(error);
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.ADD_AL_REQUEST,
        };
    }
    function success(journal, schedule) {
        return {
            type: ALBookkeepingConstants.ADD_AL_SUCCESS,
            journal,
            schedule,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.ADD_AL_FAILURE,
            error,
        };
    }
}

function deleteALSchedule(companyId, journalID) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService.deleteALSchedule(companyId, journalID).then(
            () => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success());
                return true;
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.DELETE_AL_SCHEDULE_REQUEST,
        };
    }
    function success() {
        return {
            type: ALBookkeepingConstants.DELETE_AL_SCHEDULE_SUCCESS,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.DELETE_AL_SCHEDULE_FAILURE,
            error,
        };
    }
}

function getALList(companyId, filterObj) {
    // switched companyId as first arg since filterObj is optional
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService.getALList(companyId, filterObj).then(
            ({ expenses }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success(expenses));
                return expenses;
            },
            (error) => {
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return { type: ALBookkeepingConstants.GET_AL_LIST_REQUEST };
    }
    function success(accruedLiabilities) {
        return { type: ALBookkeepingConstants.GET_AL_LIST_SUCCESS, accruedLiabilities };
    }
    function failure(error) {
        return { type: ALBookkeepingConstants.GET_AL_LIST_FAILURE, error };
    }
}

function getALSchedule(companyId, journal_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService.getALSchedule(companyId, journal_id).then(
            (result) => {
                const schedule = result.schedule,
                    journal = result.journal;
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success(journal, schedule));
                return result;
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.GET_AL_SCHEDULE_REQUEST,
        };
    }
    function success(journal, schedule) {
        return {
            type: ALBookkeepingConstants.GET_AL_SCHEDULE_SUCCESS,
            journal,
            schedule,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.GET_AL_SCHEDULE_FAILURE,
            error,
        };
    }
}

function getALDataToBeRecognized(companyId, lastDateToRecognize) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService
            .getALDataToBeRecognized(companyId, lastDateToRecognize)
            .then(
                (result) => {
                    const dataToBeRecognized = result.dataToBeRecognized;
                    const dates = result.dates;
                    const warnings = result.warnings;
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(dataToBeRecognized, dates, warnings));
                    return result;
                },
                (error) => {
                    console.log(error);
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.GET_AL_DATA_TO_BE_RECOGNIZED_REQUEST,
            loading: true,
        };
    }
    function success(dataToBeRecognized, dates, warnings) {
        return {
            type: ALBookkeepingConstants.GET_AL_DATA_TO_BE_RECOGNIZED_SUCCESS,
            dataToBeRecognized,
            dates,
            warnings,
            loaded: true,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.GET_AL_DATA_TO_BE_RECOGNIZED_FAILURE,
            error,
        };
    }
}

function confirmRecognizeALData(companyId, startDate, endDate, totalAmount) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService
            .confirmRecognizeALData(companyId, startDate, endDate, totalAmount)
            .then(
                () => {
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(endDate));
                    return true;
                },
                (error) => {
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.CONFIRM_RECOGNIZE_AL_DATA_REQUEST,
        };
    }
    function success(localALBookCloseDate) {
        return {
            type: ALBookkeepingConstants.CONFIRM_RECOGNIZE_AL_DATA_SUCCESS,
            localALBookCloseDate,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.CONFIRM_RECOGNIZE_AL_DATA_FAILURE,
            error,
        };
    }
}

function rollbackRecognizeALData(companyId, rollbackDate) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return ALBookkeepingService
            .rollbackRecognizeALData(companyId, rollbackDate)
            .then(
                () => {
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(
                        success(moment(rollbackDate).subtract(1, "month").endOf("month"))
                    );
                    return true;
                },
                (error) => {
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: ALBookkeepingConstants.ROLLBACK_RECOGNIZE_AL_DATA_REQUEST,
        };
    }
    function success(localALBookCloseDate) {
        return {
            type: ALBookkeepingConstants.ROLLBACK_RECOGNIZE_AL_DATA_SUCCESS,
            localALBookCloseDate,
        };
    }
    function failure(error) {
        return {
            type: ALBookkeepingConstants.ROLLBACK_RECOGNIZE_AL_DATA_FAILURE,
            error,
        };
    }
}

function exportALList(companyId, filterObjInit) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        const filterObj = Object.keys(filterObjInit).reduce((acc, key) => {
            if (filterObjInit[key]) acc[key] = filterObjInit[key];
            return acc;
        }, {});

        return ALBookkeepingService.exportALList(companyId, filterObj).then(
            (res) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return dispatch(success());
            },
            (error) => {
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
            }
        );
    };

    function request() {
        return { type: ALBookkeepingConstants.EXPORT_AL_LIST_REQUEST };
    }
    function success() {
        return { type: ALBookkeepingConstants.EXPORT_AL_LIST_SUCCESS };
    }
    function failure(error) {
        return { type: ALBookkeepingConstants.EXPORT_AL_LIST_FAILURE, error };
    }
}


function deleteALsInline(query) {
    return {
        type: ALBookkeepingConstants.DELETE_ALS_INLINE,
        query,
    };
}
