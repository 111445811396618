import React from "react";

import {
	CardElement,
	useStripe,
	useElements,
	ElementsConsumer,
	Elements,
} from "@stripe/react-stripe-js";

import "./payment.css";
import config from "_configs";

import { Button, Form } from "_components/Base";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(config.billing.STRIPE_PUBLIC_KEY);

export const MainStripeCardForm = ({ ...rest }) => (
	<Elements stripe={stripePromise}>
		<InjectedCheckoutStripeForm {...rest} />
	</Elements>
);

const InjectedCheckoutStripeForm = ({ ...rest }) => {
	return (
		<ElementsConsumer>
			{({ elements, stripe }) => (
				<MyStripeCardForm {...rest} elements={elements} stripe={stripe} />
			)}
		</ElementsConsumer>
	);
};

const MyCardElement = () => (
	<CardElement onReady={(el) => el.focus()}
		options={{
			style: {
				base: {
					fontSize: "16px",
					color: "#424770",
					"::placeholder": {
						color: "#aab7c4",
					},
				},
				invalid: {
					color: "#9e2146",
				},
			},
		}}
	/>
);

const MyStripeCardForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		// Block native form submission.
		event.preventDefault();

		try {
			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				return;
			}

			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const cardElement = elements.getElement(CardElement);

			const { error, token } = await stripe.createToken(cardElement);

			if (error && error.message) {
				throw new Error(error.message);
			}
			await props.processCardFunc(token.id);
		} catch (err) {
			await props.reportErrorFunc(err.error || err);
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<MyCardElement />
			<Button type="submit" className={props.buttonClassName}
				variant="outline-dark" disabled={!stripe}
			>
				{props.buttonTitle || "Subscribe"}
			</Button>
			{props.cancelButtonTitle && props.handleCancel && (
				<Button className={props.buttonClassName}
					onClick={props.handleCancel} variant="outline-dark"
				>
					{props.cancelButtonTitle}
				</Button>
			)}
		</Form>
	);
};
