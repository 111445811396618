import { userConstants, bookkeepingConstants } from "_constants";

let storageUser = localStorage.getItem("kalestack__user");
let user;
if (!storageUser || storageUser === "undefined") {
	user = null;
} else {
	user = JSON.parse(storageUser);
}

const initialState = {
	...(user ? { loggedIn: true, user } : {}),
	states: {},
};

const emptyInitialState = {
	states: {},
};

// Helper function (not allowed to call outside function)
function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

/**
 * My own implementation of "loading" flag management
 * */
export function authentication(state = initialState, action) {
	const stateImpl = authenticationImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function authenticationImpl(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return emptyInitialState;
		case userConstants.XERO_SIGN_IN_CALLBACK_REQUEST:
		case userConstants.QBO_SIGN_IN_CALLBACK_REQUEST:
		case userConstants.LOGIN_REQUEST:
			return emptyInitialState; // user: action.user
		case userConstants.XERO_SIGN_IN_CALLBACK_SUCCESS:
		case userConstants.QBO_SIGN_IN_CALLBACK_SUCCESS:
		case userConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user,
			};
		case userConstants.XERO_SIGN_IN_CALLBACK_FAILURE:
		case userConstants.QBO_SIGN_IN_CALLBACK_FAILURE:
		case userConstants.LOGIN_FAILURE:
			return {};
		case userConstants.LOGOUT:
			return {};

		case userConstants.FINISH_REGISTER_REQUEST:
		case userConstants.USERS_GET_PROFILE_REQUEST:
			// return Object.assign({}, state, {
			//   user: action.user
			// });
			return state;
		case userConstants.FINISH_REGISTER_SUCCESS:
		case userConstants.USERS_GET_PROFILE_SUCCESS:
			return Object.assign({}, state, { user: { ...(state.user || {}), ...action.user } });
		case userConstants.FINISH_REGISTER_FAILURE:
		case userConstants.USERS_GET_PROFILE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case userConstants.CHANGE_SUBSCRIPTION_REQUEST:
			return state;
		case userConstants.CHANGE_SUBSCRIPTION_SUCCESS:
			return Object.assign({}, state, {
				user: {
					...state.user,
					subscription: action.subscription,
					billing: {
						...state.user.billing,
						isBillable: true,
					},
				},
			});
		case userConstants.CHANGE_SUBSCRIPTION_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case userConstants.VERIFY_2FA_SETUP_REQUEST:
			return state;
		case userConstants.VERIFY_2FA_SETUP_SUCCESS:
			return Object.assign({}, state, {
				user: {
					...state.user,
					mfa: true
				}
			});
		case userConstants.VERIFY_2FA_SETUP_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		// In case default company changes
		case bookkeepingConstants.MAKE_COMPANY_DEFAULT_SUCCESS:
			return Object.assign({}, state, {
				user: {
					...state.user,
					default_company: {
						companyId: action.defaultCompanyId,
						companyName: action.defaultCompanyName,
					},
				},
			});

		default:
			return state;
	}
}
