export const userConstants = {
	CLEAR_ALL: "CLEAR_ALL",

	REGISTER: "USERS_REGISTER",

	REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
	REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
	REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

	FINISH_REGISTER: "USERS_FINISH_REGISTER",

	FINISH_REGISTER_REQUEST: "USERS_FINISH_REGISTER_REQUEST",
	FINISH_REGISTER_SUCCESS: "USERS_FINISH_REGISTER_SUCCESS",
	FINISH_REGISTER_FAILURE: "USERS_FINISH_REGISTER_FAILURE",

	LOGIN: "USERS_LOGIN",

	LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
	LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
	LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

	QBO_SIGN_IN_CALLBACK: "QBO_SIGN_IN_CALLBACK",

	QBO_SIGN_IN_CALLBACK_REQUEST: "QBO_SIGN_IN_CALLBACK_REQUEST",
	QBO_SIGN_IN_CALLBACK_SUCCESS: "QBO_SIGN_IN_CALLBACK_SUCCESS",
	QBO_SIGN_IN_CALLBACK_FAILURE: "QBO_SIGN_IN_CALLBACK_FAILURE",


	XERO_SIGN_IN_CALLBACK: "XERO_SIGN_IN_CALLBACK",

	XERO_SIGN_IN_CALLBACK_REQUEST: "XERO_SIGN_IN_CALLBACK_REQUEST",
	XERO_SIGN_IN_CALLBACK_SUCCESS: "XERO_SIGN_IN_CALLBACK_SUCCESS",
	XERO_SIGN_IN_CALLBACK_FAILURE: "XERO_SIGN_IN_CALLBACK_FAILURE",

	USERS_GET_PROFILE: "USERS_GET_PROFILE",

	USERS_GET_PROFILE_REQUEST: "USERS_GET_PROFILE_REQUEST",
	USERS_GET_PROFILE_SUCCESS: "USERS_GET_PROFILE_SUCCESS",
	USERS_GET_PROFILE_FAILURE: "USERS_GET_PROFILE_FAILURE",

	LOGOUT: "USERS_LOGOUT",

	USERS_GET_ALL: "USERS_GET_ALL",

	USERS_GET_ALL_REQUEST: "USERS_GET_ALL_REQUEST",
	USERS_GET_ALL_SUCCESS: "USERS_GET_ALL_SUCCESS",
	USERS_GET_ALL_FAILURE: "USERS_GET_ALL_FAILURE",

	DELETE: "USERS_DELETE",

	DELETE_REQUEST: "USERS_DELETE_REQUEST",
	DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
	DELETE_FAILURE: "USERS_DELETE_FAILURE",

	CHANGE_SUBSCRIPTION: "CHANGE_SUBSCRIPTION",

	CHANGE_SUBSCRIPTION_REQUEST: "CHANGE_SUBSCRIPTION_REQUEST",
	CHANGE_SUBSCRIPTION_SUCCESS: "CHANGE_SUBSCRIPTION_SUCCESS",
	CHANGE_SUBSCRIPTION_FAILURE: "CHANGE_SUBSCRIPTION_FAILURE",

	SET_PE_ACCOUNT: "SET_PE_ACCOUNT",

	VERIFY_2FA_SETUP: "VERIFY_2FA_SETUP",

	VERIFY_2FA_SETUP_REQUEST: "VERIFY_2FA_SETUP_REQUEST",
	VERIFY_2FA_SETUP_SUCCESS: "VERIFY_2FA_SETUP_SUCCESS",
	VERIFY_2FA_SETUP_FAILURE: "VERIFY_2FA_SETUP_FAILURE",

};
