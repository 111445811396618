import React from "react";
import { Route as RouteDom } from "react-router-dom";
import { Redirect } from 'react-router-dom';

import { DefaultLayout, SideBarLayout, SigninLayout } from "_components/Layouts";
import { connect } from "react-redux";
import config from "_configs";

const Route = ({ variant = { default: true, sidebar: false, signin: false }, component: Component, render, loggedIn, user, allowIncomplete, ...rest }) => {
    if (loggedIn && !allowIncomplete && !user?.isComplete) {
        const renderFunc = props => <Redirect
            to={{
                pathname: config.clientUrls.COMPLETE_SIGNUP,
                state: { from: props.location }
            }} />
        return <RouteDom {...rest} render={renderFunc} />
    }
    let renderFunc;
    if (render) {
        renderFunc = render;
    } else if (variant.default) {
        renderFunc = props => <DefaultLayout content={<Component {...props} />} />
    } else if (variant.default_unwrapped) {
        renderFunc = props => <DefaultLayout content={<Component {...props} />} container_wrapped={false} />
    } else if (variant.sidebar) {
        renderFunc = props => <SideBarLayout sidebar={variant.sidebar} content={<Component {...props} />} />
    } else if (variant.signin) {
        renderFunc = props => <SigninLayout content={<Component {...props} />} />
    }
    return <RouteDom {...rest} render={renderFunc} />
}


function mapState(state) {
    const { user, loggedIn } = state.authentication;
    return { user, loggedIn };
}

const actionCreators = {};

const connectedRoute = connect(
    mapState,
    actionCreators
)(Route);
export { connectedRoute as Route };
