import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import config from "_configs";
import { Route } from '_components';
import { Redirect } from 'react-router-dom';
import { userActions } from '_actions';
import moment from "moment";

function processCondition(condition, negation) {
    return negation ? !condition : condition;
}

function manageRoute(rule, loggedIn, user, ruleRedirect) {
    const NEGATION_SYMBOL = "~";
    let negation = typeof rule === "string" && rule.startsWith(NEGATION_SYMBOL);
    let _rule = negation ? rule.substring(1) : rule;

    let condition, message;
    let redirect = ruleRedirect || config.clientUrls.USER_ACCOUNT_SUMMARY;
    if (!loggedIn) {
        redirect = config.clientUrls.LOGIN;
        condition = false;
    } else if (!_rule) {
        condition = true;
    } else if (typeof _rule === "function") {
        condition = rule(user);
    } else if (_rule === "isTrial") {
        condition = processCondition(user.billing.isTrial, negation);
    } else if (_rule === "isBillable") {
        condition = processCondition(user.billing.isBillable, negation);
    } else if (_rule === "isAdmin") {
        condition = processCondition(user.isAdmin, negation);
        redirect = config.clientUrls.LOGIN;
        message = "You have not enough permissions."
    } else if (_rule === "isPaid") {
        condition = processCondition(
            moment(user.subscription.subscriptionExpr).utc().endOf("day") > moment().utc(),
            // new Date(user.subscription.subscriptionExpr) > moment().startOf("day").endOf("hour").toDate(),
            negation);
        message = "We were unable to charge your default payment method."
    } else if (_rule === "hasSubscription") {
        condition = processCondition(
            user.subscription.basicSubscriptionType !== 0,
            negation);
        redirect = config.clientUrls.SUBSCRIPTION;
        message = "You are not subscribed. Please select one of our plans."
    } else {
        condition = false;
        redirect = config.clientUrls.LOGIN;
    }
    return { redirect, condition, message }
}

/**
 * waitTillUpdate: false allows getProfile without wait (if false/undefined).
 * Please avoid updateProfile argument since it holds (locks) for a component until profile is updated.
 */
const PrivateRoute = ({ rule, ruleRedirect, updateProfile, waitTillUpdate, allowIncomplete = false, ...rest }) => {
    const [ready, setReady] = useState(!updateProfile)
    useEffect(() => {
        if (updateProfile) {
            async function fetchData() {
                try {
                    await rest.getProfile();
                    setReady(true);
                } catch (err) {
                    console.log(err)
                }
            }
            fetchData();
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { user, loggedIn } = rest;
    let mainCondition = false;
    let mainMessage = "";
    if (loggedIn && !allowIncomplete && !user?.isComplete) {
        const renderFunc = props => <Redirect
            to={{
                pathname: config.clientUrls.COMPLETE_SIGNUP,
                state: { from: props.location, redirectMessage: mainMessage }
            }} />
        return <Route allowIncomplete={true} {...rest} render={renderFunc} />
    }

    if (typeof rule === "object") {
        if (rule.or && Array.isArray(rule.or)) {
            const ruleResult = rule.or.map(r => manageRoute(r, loggedIn, user, ruleRedirect));
            mainCondition = ruleResult.some(({ condition }) => condition)
        } else if (rule.and && Array.isArray(rule.and)) {
            const ruleResult = rule.and.map(r => manageRoute(r, loggedIn, user, ruleRedirect));
            mainCondition = ruleResult.every(({ condition }) => condition)
            mainMessage = ruleResult[0].message;
            if (!mainCondition && !ruleRedirect) {
                ruleRedirect = ruleResult.find(({ condition }) => !condition).redirect
            }
        } else {
            console.error("Cannot process Object rule in the route", rule)
        }
    } else {
        const { condition, redirect, message } = manageRoute(rule, loggedIn, user, ruleRedirect);
        mainCondition = condition;
        ruleRedirect = redirect;
        mainMessage = message;
    }

    // alternative to componentWillMount (waitTillUpdate allows getProfile without wait.)
    if (!ready && waitTillUpdate) return <></>

    if (!mainCondition) {
        const renderFunc = props => <Redirect
            to={{
                pathname: ruleRedirect || config.clientUrls.LOGIN,
                state: { from: props.location, redirectMessage: mainMessage }
            }} />
        return <Route {...rest} render={renderFunc} />
    }
    return <Route allowIncomplete={allowIncomplete} {...rest} />
    // return <Route {...rest} render={props => {
    //     return mainCondition
    //         ? <Component {...props} />
    //         : <Redirect to={{ pathname: ruleRedirect || config.clientUrls.LOGIN, state: { from: props.location } }} />
    // }} />
}

function mapState(state) {
    const { user, loggedIn } = state.authentication;
    return { user, loggedIn };
}

const actionCreators = {
    getProfile: userActions.getProfile
};

const connectedPrivateRoute = connect(
    mapState,
    actionCreators
)(PrivateRoute);
export { connectedPrivateRoute as PrivateRoute };
