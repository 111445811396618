import { userConstants } from "_constants";
import { userService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";
import { history } from "_helpers";

import config from "_configs";

const changeSubscription = {
	request: () => {
		return { type: userConstants.CHANGE_SUBSCRIPTION_REQUEST };
	},
	success: (subscription) => {
		return { type: userConstants.CHANGE_SUBSCRIPTION_SUCCESS, subscription };
	},
	failure: (error) => {
		return { type: userConstants.CHANGE_SUBSCRIPTION_FAILURE, error };
	},
};

export const userActions = {
	login,
	logout,
	forgot,
	reset,
	verifyEmail,
	register,
	finish_register,
	getProfile,
	delete: _delete,

	saveLastActionTimeStamp,
	verifyLastActionTimeStamp,

	initiate2FASetup,
	verify2FASetup,

	changeSubscription,

	submitFeedback,

	getSecurityQuestion,
	answerSecurityQuestion,
};

function saveLastActionTimeStamp() {
	localStorage.setItem(
		"kalestack__last_action_timestamp",
		// milliseconds
		new Date().getTime() + 1000 * 60 * config.IDDLE_SESSION_LOGOUT_TIMER
	);
}

function verifyLastActionTimeStamp() {
	const lastActionTs = parseInt(localStorage.getItem("kalestack__last_action_timestamp"));
	return lastActionTs && new Date() <= lastActionTs;
}

function login(email, password, code) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request({ email }));

		return userService.login(email, password, code).then(
			({ user }) => {
				localStorage.setItem("kalestack__user", JSON.stringify(user));
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(user));
				// history.push(config.clientUrls.MOKAFILE__COMPANY_HOME(user.default_company.companyId));
				return user;
			},
			(error) => {
				if (!["info", "field"].includes(error.type)) {
					dispatch(failure(error.error));
					dispatch(alertActions.alert(error));
				}
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request(user) {
		return { type: userConstants.LOGIN_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error };
	}
}

function logout() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return userService.logout().then(
			(response) => {
				// remove user from local storage to log user out
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch({ type: userConstants.LOGOUT });
				localStorage.removeItem("kalestack__user");
				dispatch({ type: userConstants.CLEAR_ALL });
				dispatch(alertActions.clearPersistentError());
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function forgot(email) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return userService.forgot(email).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				history.push(config.clientUrls.LOGIN);
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function reset(token, newPassword, confirmNew) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return userService.reset(token, newPassword, confirmNew).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				history.push(config.clientUrls.LOGIN);
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function getSecurityQuestion(token) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.getSecurityQuestion(token).then(
			({ question }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return question;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function answerSecurityQuestion(token, answer) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.answerSecurityQuestion(token, answer).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return response;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function verifyEmail(token) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		history.push(config.clientUrls.LOGIN);
		return userService.verifyEmail(token).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function register(user) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request(user));

		return userService.register(user).then(
			(user) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success());
				dispatch(
					alertActions.success(
						"You have successfully registered. Please, check your email to verify your account."
					)
				);
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request(user) {
		return { type: userConstants.REGISTER_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.REGISTER_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.REGISTER_FAILURE, error };
	}
}

function finish_register(user) {
	const previousUser = localStorage.getItem("kalestack__user");
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request(previousUser ? JSON.parse(previousUser) : {})); // REQUIRED otherwise previousUser will be just string

		return userService.finish_register(user).then(
			({ user }) => {
				localStorage.setItem("kalestack__user", JSON.stringify(user));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return dispatch(success(user));
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request(user) {
		return { type: userConstants.FINISH_REGISTER_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.FINISH_REGISTER_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.FINISH_REGISTER_FAILURE, error };
	}
}

function getProfile(include_tax = false) {
	const previousUser = localStorage.getItem("kalestack__user");
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request(previousUser ? JSON.parse(previousUser) : {})); // REQUIRED otherwise previousUser will be just string

		return userService.getProfile(include_tax).then(
			({ user }) => {
				localStorage.setItem("kalestack__user", JSON.stringify(user));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return dispatch(success(user));
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
			}
		);
	};

	function request(user) {
		return { type: userConstants.USERS_GET_PROFILE_REQUEST, user };
	}
	function success(user) {
		return { type: userConstants.USERS_GET_PROFILE_SUCCESS, user };
	}
	function failure(error) {
		return { type: userConstants.USERS_GET_PROFILE_FAILURE, error };
	}
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request(id));

		userService.delete(id).then(
			(user) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return dispatch(success(id));
			},
			(error) => dispatch(failure(id, error.error))
		);
	};

	function request(id) {
		return { type: userConstants.DELETE_REQUEST, id };
	}
	function success(id) {
		return { type: userConstants.DELETE_SUCCESS, id };
	}
	function failure(id, error) {
		return { type: userConstants.DELETE_FAILURE, id, error };
	}
}

function initiate2FASetup() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.initiate2FASetup().then(
			({ message, url }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				// dispatch(alertActions.success(message));
				return url;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function verify2FASetup(code) {
	return (dispatch) => {
		dispatch(request())
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.verify2FASetup(code).then(
			({ message }) => {
				dispatch(success())
				dispatch(loadingAPIActions.stopLoadingAPI());
				// history.replace(config.clientUrls.USER_ACCOUNT_SUMMARY);
				dispatch(alertActions.success(message));
				return true;
			},
			(error) => {
				if (!["info", "field"].includes(error.type)) {
					dispatch(failure(error.error));
					dispatch(alertActions.alert(error));
				}
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: userConstants.VERIFY_2FA_SETUP_REQUEST };
	}
	function success() {
		return { type: userConstants.VERIFY_2FA_SETUP_SUCCESS };
	}
	function failure(error) {
		return { type: userConstants.VERIFY_2FA_SETUP_FAILURE, error };
	}
}

function submitFeedback(feedback) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return userService.submitFeedback(feedback).then(
			({ message }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(message));
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}