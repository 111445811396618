import { PEBookkeepingConstants } from "_constants";
import { PEBookkeepingService } from "_services";
import { alertActions, loadingAPIActions } from ".";
import moment from "moment";

export const PEBookkeepingActions = {
    setPEAccount,
    addPE,

    deletePESchedule,

    getPEList,
    getPESchedule,
    deletePEsInline,

    getPEDataToBeRecognized,
    confirmRecognizePEData,
    rollbackRecognizePEData,

    exportPEList,
};

function setPEAccount(companyId, id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService.setPEAccount(companyId, id).then(
            (result) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return dispatch(success(result.peAccountName));
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return { type: PEBookkeepingConstants.SET_PE_ACCOUNT_REQUEST };
    }
    function success(peAccountName) {
        return {
            type: PEBookkeepingConstants.SET_PE_ACCOUNT_SUCCESS,
            peAccountName,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.SET_PE_ACCOUNT_FAILURE,
            error,
        };
    }
}

function addPE(
    companyId,
    prepaid_expense_data,
    buildOwnSchedule,
    ownSchedule
) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService
            .addPE(
                companyId,
                prepaid_expense_data,
                buildOwnSchedule,
                ownSchedule
            )
            .then(
                (result) => {
                    const schedule = result.schedule,
                        journal = result.journal;
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(journal, schedule));
                    return result;
                },
                (error) => {
                    console.log(error);
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.ADD_PE_REQUEST,
        };
    }
    function success(journal, schedule) {
        return {
            type: PEBookkeepingConstants.ADD_PE_SUCCESS,
            journal,
            schedule,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.ADD_PE_FAILURE,
            error,
        };
    }
}

function deletePESchedule(companyId, journalID) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService.deletePESchedule(companyId, journalID).then(
            () => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success());
                return true;
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.DELETE_PE_SCHEDULE_REQUEST,
        };
    }
    function success() {
        return {
            type: PEBookkeepingConstants.DELETE_PE_SCHEDULE_SUCCESS,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.DELETE_PE_SCHEDULE_FAILURE,
            error,
        };
    }
}

function getPEList(companyId, filterObj) {
    // switched companyId as first arg since filterObj is optional
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService.getPEList(companyId, filterObj).then(
            ({ expenses }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success(expenses));
                return expenses;
            },
            (error) => {
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return { type: PEBookkeepingConstants.GET_PE_LIST_REQUEST };
    }
    function success(prepaidExpenses) {
        return { type: PEBookkeepingConstants.GET_PE_LIST_SUCCESS, prepaidExpenses };
    }
    function failure(error) {
        return { type: PEBookkeepingConstants.GET_PE_LIST_FAILURE, error };
    }
}

function getPESchedule(companyId, journal_id) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService.getPESchedule(companyId, journal_id).then(
            (result) => {
                const schedule = result.schedule,
                    journal = result.journal;
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(success(journal, schedule));
                return result;
            },
            (error) => {
                console.log(error);
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.GET_PE_SCHEDULE_REQUEST,
        };
    }
    function success(journal, schedule) {
        return {
            type: PEBookkeepingConstants.GET_PE_SCHEDULE_SUCCESS,
            journal,
            schedule,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.GET_PE_SCHEDULE_FAILURE,
            error,
        };
    }
}

function getPEDataToBeRecognized(companyId, lastDateToRecognize) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService
            .getPEDataToBeRecognized(companyId, lastDateToRecognize)
            .then(
                (result) => {
                    const dataToBeRecognized = result.dataToBeRecognized;
                    const dates = result.dates;
                    const warnings = result.warnings;
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(dataToBeRecognized, dates, warnings));
                    return result;
                },
                (error) => {
                    console.log(error);
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_REQUEST,
            loading: true,
        };
    }
    function success(dataToBeRecognized, dates, warnings) {
        return {
            type: PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_SUCCESS,
            dataToBeRecognized,
            dates,
            warnings,
            loaded: true,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_FAILURE,
            error,
        };
    }
}

function confirmRecognizePEData(companyId, startDate, endDate, totalAmount) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService
            .confirmRecognizePEData(companyId, startDate, endDate, totalAmount)
            .then(
                () => {
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(success(endDate));
                    return true;
                },
                (error) => {
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_REQUEST,
        };
    }
    function success(localPEBookCloseDate) {
        return {
            type: PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_SUCCESS,
            localPEBookCloseDate,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_FAILURE,
            error,
        };
    }
}

function rollbackRecognizePEData(companyId, rollbackDate) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        return PEBookkeepingService
            .rollbackRecognizePEData(companyId, rollbackDate)
            .then(
                () => {
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(
                        success(moment(rollbackDate).subtract(1, "month").endOf("month"))
                    );
                    return true;
                },
                (error) => {
                    dispatch(failure(error.error));
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
    };

    function request() {
        return {
            type: PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_REQUEST,
        };
    }
    function success(localPEBookCloseDate) {
        return {
            type: PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_SUCCESS,
            localPEBookCloseDate,
        };
    }
    function failure(error) {
        return {
            type: PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_FAILURE,
            error,
        };
    }
}

function exportPEList(companyId, filterObjInit) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        dispatch(request());

        const filterObj = Object.keys(filterObjInit).reduce((acc, key) => {
            if (filterObjInit[key]) acc[key] = filterObjInit[key];
            return acc;
        }, {});

        return PEBookkeepingService.exportPEList(companyId, filterObj).then(
            (res) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return dispatch(success());
            },
            (error) => {
                dispatch(failure(error.error));
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
            }
        );
    };

    function request() {
        return { type: PEBookkeepingConstants.EXPORT_PE_LIST_REQUEST };
    }
    function success() {
        return { type: PEBookkeepingConstants.EXPORT_PE_LIST_SUCCESS };
    }
    function failure(error) {
        return { type: PEBookkeepingConstants.EXPORT_PE_LIST_FAILURE, error };
    }
}


function deletePEsInline(query) {
    return {
        type: PEBookkeepingConstants.DELETE_PES_INLINE,
        query,
    };
}
