import { bookkeepingConstants, userConstants } from "_constants";
import { bookkeepingService } from "_services";
import { alertActions, loadingAPIActions } from ".";
import { history } from "_helpers";

import config from "_configs";

export const bookkeepingActions = {
	qboConnect,
	qboSignIn,
	qboCallback,
	qboSignInCallback,
	qboDisconnect,

	xeroConnect,
	xeroSignIn,
	xeroCallback,
	xeroSignInCallback,
	xeroDisconnect,

	getCompanies,
	getCompanyDetails,
	createCompany,
	getDefaultOrCreateCompany,

	getAccountList,

	makeCompanyDefault,
	deleteCompany,

	qboDisconnectByRealmId,
};

function qboConnect(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.qboConnect(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success());
				window.location.href = response.url;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.QBO_CONNECT_REQUEST };
	}
	function success() {
		return { type: bookkeepingConstants.QBO_CONNECT_SUCCESS };
	}
	function failure(error) {
		return { type: bookkeepingConstants.QBO_CONNECT_FAILURE, error };
	}
}

function qboSignIn() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.qboSignIn().then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success());
				window.location.href = response.url;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.QBO_SIGN_IN_REQUEST };
	}
	function success() {
		return { type: bookkeepingConstants.QBO_SIGN_IN_SUCCESS };
	}
	function failure(error) {
		return { type: bookkeepingConstants.QBO_SIGN_IN_FAILURE, error };
	}
}

function qboCallback(url) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.qboCallback(url).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(response.companyId, response.isReconnectionNeeded));
				return { success: response.success, companyId: response.companyId };
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.QBO_CALLBACK_REQUEST };
	}
	function success(companyId, isReconnectionNeeded) {
		return {
			type: bookkeepingConstants.QBO_CALLBACK_SUCCESS,
			companyId,
			isReconnectionNeeded,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.QBO_CALLBACK_FAILURE, error };
	}
}

function qboSignInCallback(url) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.qboSignInCallback(url).then(
			({ user }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(user));
				return user;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: userConstants.QBO_SIGN_IN_CALLBACK_REQUEST };
	}
	function success(user) {
		return {
			type: userConstants.QBO_SIGN_IN_CALLBACK_SUCCESS,
			user
		};
	}
	function failure(error) {
		return { type: userConstants.QBO_SIGN_IN_CALLBACK_FAILURE, error };
	}
}

function qboDisconnect(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.qboDisconnect(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(response.companyId, response.isReconnectionNeeded));
				// window.location.href = disconnectUrl;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.QBO_DISCONNECT_REQUEST };
	}
	function success(companyId, isReconnectionNeeded) {
		return {
			type: bookkeepingConstants.QBO_DISCONNECT_SUCCESS,
			companyId,
			isReconnectionNeeded,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.QBO_DISCONNECT_FAILURE, error };
	}
}

function xeroConnect(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.xeroConnect(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success());
				window.location.href = response.url;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.XERO_CONNECT_REQUEST };
	}
	function success() {
		return { type: bookkeepingConstants.XERO_CONNECT_SUCCESS };
	}
	function failure(error) {
		return { type: bookkeepingConstants.XERO_CONNECT_FAILURE, error };
	}
}

function xeroSignIn() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.xeroSignIn().then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success());
				window.location.href = response.url;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.XERO_SIGN_IN_REQUEST };
	}
	function success() {
		return { type: bookkeepingConstants.XERO_SIGN_IN_SUCCESS };
	}
	function failure(error) {
		return { type: bookkeepingConstants.XERO_SIGN_IN_FAILURE, error };
	}
}

function xeroCallback(url) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.xeroCallback(url).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(response.companyId, response.isReconnectionNeeded));
				if (!response.success && !response.isReconnectionNeeded) {
					dispatch(alertActions.error(response.message));
				}
				return { success: response.success, companyId: response.companyId };
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.XERO_CALLBACK_REQUEST };
	}
	function success(companyId, isReconnectionNeeded) {
		return {
			type: bookkeepingConstants.XERO_CALLBACK_SUCCESS,
			companyId,
			isReconnectionNeeded,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.XERO_CALLBACK_FAILURE, error };
	}
}

function xeroSignInCallback(url) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.xeroSignInCallback(url).then(
			({ user }) => {

				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(user));
				return user;
			},
			(error) => {
				console.log(error);
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: userConstants.XERO_SIGN_IN_CALLBACK_REQUEST };
	}
	function success(user) {
		return {
			type: userConstants.XERO_SIGN_IN_CALLBACK_SUCCESS, user,
		};
	}
	function failure(error) {
		return { type: userConstants.XERO_SIGN_IN_CALLBACK_FAILURE, error };
	}
}

function xeroDisconnect(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.xeroDisconnect(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(response.companyId, response.isReconnectionNeeded));
				// window.location.href = disconnectUrl;
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.XERO_DISCONNECT_REQUEST };
	}
	function success(companyId, isReconnectionNeeded) {
		return {
			type: bookkeepingConstants.XERO_DISCONNECT_SUCCESS,
			companyId,
			isReconnectionNeeded,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.XERO_DISCONNECT_FAILURE, error };
	}
}

function getCompanies() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.getCompanies().then(
			({ companies }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(companies));
				return companies
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
	function request() {
		return { type: bookkeepingConstants.GET_COPMANIES_LIST_REQUEST };
	}
	function success(companies) {
		return {
			type: bookkeepingConstants.GET_COPMANIES_LIST_SUCCESS,
			companies,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.GET_COPMANIES_LIST_FAILURE, error };
	}
}

function getCompanyDetails(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.getCompanyDetails(companyId).then(
			(company) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(company));
				return company;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
	function request() {
		return { type: bookkeepingConstants.GET_COMPANY_DETAILS_REQUEST };
	}
	function success(company) {
		return {
			type: bookkeepingConstants.GET_COMPANY_DETAILS_SUCCESS,
			company,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.GET_COMPANY_DETAILS_FAILURE, error };
	}
}

function createCompany() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.createCompany().then(
			(companyId) => {
				dispatch(success(companyId));
				dispatch(loadingAPIActions.stopLoadingAPI());
				history.push(config.clientUrls.MOKAFILE__COMPANY_HOME(companyId));
				return companyId;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
	function request() {
		return { type: bookkeepingConstants.CREATE_COMPANY_REQUEST };
	}
	function success(companyId) {
		return {
			type: bookkeepingConstants.CREATE_COMPANY_SUCCESS,
			companyId,
		};
	}
	function failure(error) {
		return { type: bookkeepingConstants.CREATE_COMPANY_FAILURE, error };
	}
}

function getDefaultOrCreateCompany() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.getDefaultOrCreateCompany().then(
			(companyId) => {
				dispatch(success(companyId));
				dispatch(loadingAPIActions.stopLoadingAPI());
				history.push(config.clientUrls.MOKAFILE__COMPANY_HOME(companyId));
				return companyId;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
	function request() {
		return { type: bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_REQUEST };
	}
	function success(companyId) {
		return {
			type: bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_SUCCESS,
			companyId,
		};
	}
	function failure(error) {
		return {
			type: bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_FAILURE,
			error,
		};
	}
}

function getAccountList(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.getAccountList(companyId).then(
			({ accounts }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(accounts));
				return accounts;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.GET_ACCOUNT_LIST_REQUEST };
	}
	function success(accounts) {
		return { type: bookkeepingConstants.GET_ACCOUNT_LIST_SUCCESS, accounts };
	}
	function failure(error) {
		return { type: bookkeepingConstants.GET_ACCOUNT_LIST_FAILURE, error };
	}
}

function deleteCompany(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.deleteCompany(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(success(response.companyId));
				return true;
			},
			(error) => {
				console.log(error);
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return {
			type: bookkeepingConstants.DELETE_COMPANY_REQUEST,
		};
	}
	function success(companyId) {
		return {
			type: bookkeepingConstants.DELETE_COMPANY_SUCCESS,
			companyId,
		};
	}
	function failure(error) {
		return {
			type: bookkeepingConstants.DELETE_COMPANY_FAILURE,
			error,
		};
	}
}

function makeCompanyDefault(companyId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return bookkeepingService.makeCompanyDefault(companyId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				dispatch(success(response._id, response.name));
				// @TODO: find a better way to keep track of the user changes
				let storageUser = localStorage.getItem("kalestack__user");
				if (storageUser && storageUser !== "undefined") {
					let user = JSON.parse(storageUser);
					user.default_company = {
						companyId: response._id,
						companyName: response.name,
					};
					localStorage.setItem("kalestack__user", JSON.stringify(user));
				}
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
			}
		);
	};

	function request() {
		return { type: bookkeepingConstants.MAKE_COMPANY_DEFAULT_REQUEST };
	}
	function success(defaultCompanyId, defaultCompanyName) {
		return {
			type: bookkeepingConstants.MAKE_COMPANY_DEFAULT_SUCCESS,
			defaultCompanyId,
			defaultCompanyName,
		};
	}
	function failure(error) {
		return {
			type: bookkeepingConstants.MAKE_COMPANY_DEFAULT_FAILURE,
			error,
		};
	}
}

function qboDisconnectByRealmId(realmId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return bookkeepingService.qboDisconnectByRealmId(realmId).then(
			({ message }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(message));
				return true
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}