import React from "react";
import { Image } from "_components/Base";


const styles = {
    imageLeft: {
        clear: "right",
        float: "left",
        marginRight: "7px",
        marginBottom: "7px",
        marginTop: "7px",
    },
    imageRight: {
        clear: "left",
        float: "right",
        marginLeft: "7px",
        marginBottom: "7px",
        marginTop: "7px",
    },
    imageFull: {
        clear: "rigth",
        margin: "7px"
    },
    internalText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(50, 50, 50, 0.4)",
        color: "white",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    container: {
        position: "relative",
        textAlign: "center"
    }
}

export const ImageRight = ({ src, style = {}, ...props }) => {
    return <Image style={{ ...styles.imageRight, ...style }} src={src} {...props} />
}

export const ImageLeft = ({ src, style = {}, ...props }) => {
    return <Image style={{ ...styles.imageLeft, ...style }} src={src} {...props} />
}

export const ImageFull = ({ src, ...props }) => {
    return <Image width="100%" src={src} {...props} fluid />
}

export const ImageHeader = ({ children, src, style = {}, ...props }) => {
    return <div style={styles.container}>
        <Image width="100%" src={src}
            style={{ height: "200px", objectFit: "cover", ...style }}
            {...props} />
        <div style={styles.internalText}>
            {children}
        </div>
    </div >
}