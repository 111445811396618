import React from "react";
import { Button } from "_components/Base";

const ButtonXEROConnect = ({ onClick, ...rest }) => (
	<Button isLink={true} onClick={onClick}>
		<img
			style={{ border: "1px solid white", borderRadius: "5px" }}
			src="/assets/img/btn_xero/connect-blue.svg"
			// height="48"
			alt="Connect to XERO"
			className="integration-button"
		/>
	</Button>
);

const ButtonXERODisconnect = ({ onClick, title = "Disconnect from XERO*", ...rest }) => (
	// <Button className="disconnect-integration-button"
	// 	type="button" variant="outline-secondary"
	// 	onClick={onClick}
	// >
	<Button isLink={true} onClick={onClick}>
		<img
			style={{ border: "1px solid white", borderRadius: "5px" }}
			src="/assets/img/btn_xero/disconnect-blue.svg"
			// height="48"
			alt="Connect to XERO"
			className="integration-button"
		/>
	</Button>
);


const ButtonXEROSignIn = ({ onClick, ...rest }) => (
	<Button variant="outline-primary" onClick={onClick} {...rest}>
		<div className="d-flex flex-row justify-content-between">
			<span></span>
			<span>Sign in with Xero</span>
			<img
				style={{ height: "20px" }}
				src="/assets/img/btn_xero/xero.svg"
				alt="Sign in with Xero"
				className="integration-button"
			/>
		</div>
	</Button>
);

export { ButtonXEROConnect, ButtonXERODisconnect, ButtonXEROSignIn };
