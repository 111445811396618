import { alertActions } from "_actions";
import { webfaceStateConstants } from "_constants";

export const webfaceStateActions = {
  changePEPanel,
  changeALPanel,
  changeModalWindowStatus
};

function changePEPanel(newPanel) {
  return dispatch => {
    dispatch(alertActions.clearPersistentError());
    dispatch({ type: webfaceStateConstants.CHANGE_PE_PANNEL, newPanel });
  };
}

function changeALPanel(newPanel) {
  return dispatch => {
    dispatch(alertActions.clearPersistentError());
    dispatch({ type: webfaceStateConstants.CHANGE_AL_PANNEL, newPanel });
  };
}

function changeModalWindowStatus() {
  return { type: webfaceStateConstants.CHANGE_MODAL_WINDOW_STATUS };
}
