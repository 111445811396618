import React, { Component } from "react";

import { LinkContainer } from "react-router-bootstrap";
import { Button, Nav, NavDropdown } from "_components/Base";
import { SettingsIcon } from "_components";

import { connect } from "react-redux";
import config from "_configs";
import { history } from "_helpers";
import { ServiceList } from "./LeftNavBar";

class RightNavBar extends Component {

	renderMDplus(companyId, companyName) {
		const { user } = this.props;
		let items = [];
		if (companyId) {
			items.push({ to: config.clientUrls.MOKAFILE__COMPANY_HOME(companyId), exact: true, title: "Company Settings" })
		}
		items.push({ to: config.clientUrls.MOKAFILE__NEW_COMPANY, exact: false, title: "Add Company" })
		items.push({ to: config.clientUrls.MOKAFILE__LIST_COMPANIES, exact: false, title: "List Companies" })
		items.push(null);
		items.push({ to: config.clientUrls.USER_ACCOUNT_SUMMARY, exact: true, title: "Account" })
		items.push(null);
		items.push({ to: config.clientUrls.SUBSCRIPTION, exact: true, title: "Subscriptions" })

		items.push({ to: config.clientUrls.HELP, exact: false, title: "Support" })
		items.push({ to: config.clientUrls.POLICY, exact: true, title: "Privacy Policy" })
		items.push({ to: config.clientUrls.TERMS, exact: true, title: "Terms & Conditions" })
		items.push(null);
		items.push({ to: config.clientUrls.LOGIN, exact: true, title: "Logout" })

		return (<>
			{/* MD plus! */}
			<NavDropdown alignRight className="no-caret d-none d-md-block" title={
				<div className="d-flex flex-row">
					<div className="d-flex flex-column mr-2 text-right">
						<p className="font-bold mb-0">{companyName}</p>
						<p className="mb-0">{user.email}</p>
					</div>
					<SettingsIcon className="d-none d-md-block my-auto" style={{ fontSize: "20px" }} />
				</div>
			} id="collasible-nav-dropdown">
				{items.map((item, indx) => {
					if (!item) {
						return <NavDropdown.Divider key={indx} />
					}
					return <LinkContainer key={indx} exact={item.exact}
						to={item.to} activeClassName=""
					>
						<NavDropdown.Item>{item.title}</NavDropdown.Item>
					</LinkContainer>
				})}
			</NavDropdown>

			{/* SM minus! */}
			<Nav.Item className="d-md-none">
				<div className="d-flex flex-column mr-2 text-left">
					<p className="font-bold mb-0">{companyName}</p>
					<p className="mb-0">{user.email}</p>
				</div>
			</Nav.Item>
			<ServiceList companyId={companyId} className={"d-md-none"} />
			{items.map((item, indx) => {
				if (!item) return <hr key={indx} className="d-md-none full-width my-0" />
				return <LinkContainer className="d-md-none" key={indx} to={item.to}>
					<Nav.Item><Nav.Link active={false} href={item.to}>{item.title}</Nav.Link></Nav.Item>
				</LinkContainer>
			})}
		</>)
	}
	renderCompanyNavBar() {
		const { company, user } = this.props;
		let companyId, companyName;
		if (company.companyId) {
			companyName = company.companyName || "Company";
			companyId = company.companyId;
		} else if (user.default_company) {
			companyName = user.default_company.companyName || "Company";
			companyId = user.default_company.companyId;
		}

		if (companyName && companyName.length > 22) {
			companyName = companyName.substring(0, 22) + "...";
		}

		return <>
			{this.renderMDplus(companyId, companyName)}
		</>
	}

	/**
	 * Renders navbar for not anonymous person
	 */
	renderNonAuthorizedNavBar() {
		return (
			<>
				<Button variant="link" className="nav-link" onClick={() => { history.push(config.clientUrls.LOGIN) }}>
					Sign in
				</Button>
				<Button variant="primary" className="ml-3" onClick={() => { history.push(config.clientUrls.REGISTER) }}>
					Sign up
				</Button>
			</>
		);
	}
	render() {
		const { loggedIn } = this.props;
		const content = loggedIn
			? this.renderCompanyNavBar()
			: this.renderNonAuthorizedNavBar();
		return (
			<Nav activeKey="/" className="ml-auto">
				{content}
			</Nav>
		);
	}
}

function mapState(state) {
	const { company } = state.bookkeeping;
	const { user, loggedIn } = state.authentication;
	return { user, loggedIn, company };
}

const actionCreators = {};

const connectedNavBar = connect(mapState, actionCreators)(RightNavBar);
export { connectedNavBar as RightNavBar };
