export const bookkeepingConstants = {
	QBO_CONNECT: "QBO_CONNECT",

	QBO_CONNECT_REQUEST: "QBO_CONNECT_REQUEST",
	QBO_CONNECT_SUCCESS: "QBO_CONNECT_SUCCESS",
	QBO_CONNECT_FAILURE: "QBO_CONNECT_FAILURE",

	QBO_SIGN_IN: "QBO_SIGN_IN",

	QBO_SIGN_IN_REQUEST: "QBO_SIGN_IN_REQUEST",
	QBO_SIGN_IN_SUCCESS: "QBO_SIGN_IN_SUCCESS",
	QBO_SIGN_IN_FAILURE: "QBO_SIGN_IN_FAILURE",

	QBO_CALLBACK: "QBO_CALLBACK",

	QBO_CALLBACK_REQUEST: "QBO_CALLBACK_REQUEST",
	QBO_CALLBACK_SUCCESS: "QBO_CALLBACK_SUCCESS",
	QBO_CALLBACK_FAILURE: "QBO_CALLBACK_FAILURE",

	QBO_DISCONNECT: "QBO_DISCONNECT",

	QBO_DISCONNECT_REQUEST: "QBO_DISCONNECT_REQUEST",
	QBO_DISCONNECT_SUCCESS: "QBO_DISCONNECT_SUCCESS",
	QBO_DISCONNECT_FAILURE: "QBO_DISCONNECT_FAILURE",

	XERO_CONNECT: "XERO_CONNECT",

	XERO_CONNECT_REQUEST: "XERO_CONNECT_REQUEST",
	XERO_CONNECT_SUCCESS: "XERO_CONNECT_SUCCESS",
	XERO_CONNECT_FAILURE: "XERO_CONNECT_FAILURE",

	XERO_SIGN_IN: "XERO_SIGN_IN",

	XERO_SIGN_IN_REQUEST: "XERO_SIGN_IN_REQUEST",
	XERO_SIGN_IN_SUCCESS: "XERO_SIGN_IN_SUCCESS",
	XERO_SIGN_IN_FAILURE: "XERO_SIGN_IN_FAILURE",

	XERO_CALLBACK: "XERO_CALLBACK",

	XERO_CALLBACK_REQUEST: "XERO_CALLBACK_REQUEST",
	XERO_CALLBACK_SUCCESS: "XERO_CALLBACK_SUCCESS",
	XERO_CALLBACK_FAILURE: "XERO_CALLBACK_FAILURE",

	XERO_DISCONNECT: "XERO_DISCONNECT",

	XERO_DISCONNECT_REQUEST: "XERO_DISCONNECT_REQUEST",
	XERO_DISCONNECT_SUCCESS: "XERO_DISCONNECT_SUCCESS",
	XERO_DISCONNECT_FAILURE: "XERO_DISCONNECT_FAILURE",

	GET_COPMANIES_LIST: "GET_COPMANIES_LIST",

	GET_COPMANIES_LIST_REQUEST: "GET_COPMANIES_LIST_REQUEST",
	GET_COPMANIES_LIST_SUCCESS: "GET_COPMANIES_LIST_SUCCESS",
	GET_COPMANIES_LIST_FAILURE: "GET_COPMANIES_LIST_FAILURE",

	GET_COMPANY_DETAILS: "GET_COMPANY_DETAILS",

	GET_COMPANY_DETAILS_REQUEST: "GET_COMPANY_DETAILS_REQUEST",
	GET_COMPANY_DETAILS_SUCCESS: "GET_COMPANY_DETAILS_SUCCESS",
	GET_COMPANY_DETAILS_FAILURE: "GET_COMPANY_DETAILS_FAILURE",

	GET_DEFAULT_CREATE_COMPANY: "GET_DEFAULT_CREATE_COMPANY",

	GET_DEFAULT_CREATE_COMPANY_REQUEST: "GET_DEFAULT_CREATE_COMPANY_REQUEST",
	GET_DEFAULT_CREATE_COMPANY_SUCCESS: "GET_DEFAULT_CREATE_COMPANY_SUCCESS",
	GET_DEFAULT_CREATE_COMPANY_FAILURE: "GET_DEFAULT_CREATE_COMPANY_FAILURE",

	CREATE_COMPANY: "CREATE_COMPANY",

	CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
	CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
	CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",

	GET_ACCOUNT_LIST: "GET_ACCOUNT_LIST",

	GET_ACCOUNT_LIST_REQUEST: "GET_ACCOUNT_LIST_REQUEST",
	GET_ACCOUNT_LIST_SUCCESS: "GET_ACCOUNT_LIST_SUCCESS",
	GET_ACCOUNT_LIST_FAILURE: "GET_ACCOUNT_LIST_FAILURE",

	MAKE_COMPANY_DEFAULT: "MAKE_COMPANY_DEFAULT",

	MAKE_COMPANY_DEFAULT_REQUEST: "MAKE_COMPANY_DEFAULT_REQUEST",
	MAKE_COMPANY_DEFAULT_SUCCESS: "MAKE_COMPANY_DEFAULT_SUCCESS",
	MAKE_COMPANY_DEFAULT_FAILURE: "MAKE_COMPANY_DEFAULT_FAILURE",

	DELETE_COMPANY: "DELETE_COMPANY",

	DELETE_COMPANY_REQUEST: "DELETE_COMPANY_REQUEST",
	DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
	DELETE_COMPANY_FAILURE: "DELETE_COMPANY_FAILURE",

	DELETE_PES_INLINE: "DELETE_PES_INLINE",
};
