import React, { Component } from "react";
import { Modal } from "_components/Base";
import $ from "jquery";

export class Confirm extends Component {
	componentDidMount() {
		this.promise = new $.Deferred();
		// this.promise2 = new $.Deferred();
		// return ReactDOM.findDOMNode(this.refs.confirm).focus();
	}

	async handleConfirm(e) {
		e.preventDefault();
		return this.promise.resolve({ success: true });
	}

	async handleCancel(e) {
		e.preventDefault();
		return this.promise.resolve({ success: false });
	}

	render() {
		return (
			<>
				<Modal show={true} title={this.props.title || "Confirmation"}
					onHide={(e) => this.handleCancel(e)}
					onSubmit={(e) => this.handleConfirm(e)}
					onCancel={(e) => this.handleCancel(e)}
					submitTitle={this.props.confirmLabel || "Confirm"}
					cancelTitle={this.props.cancelLabel || "Cancel"}
					focusOnSubmit
				>
					<p>{this.props.message}</p>
				</Modal>
			</>
		);
	}

}
