import React from "react";
import { Modal as ModalBS } from "react-bootstrap";
import { Button } from "./Button";

export const Modal = ({
    children,
    show,
    title,
    size = "lg",
    onSubmit,
    onCancel,
    onHide,
    submitTitle = "Ok",
    cancelTitle = "Cancel",
    focusOnSubmit = false,
    ...rest
}) => {
    return (
        <ModalBS show={show} onHide={onHide} size={size}>
            <ModalBS.Header className="no-borders" closeButton>
                <ModalBS.Title className="text-subheading">{title}</ModalBS.Title>
            </ModalBS.Header>
            <ModalBS.Body className="pt-0 pb-2">{children}</ModalBS.Body>
            {(onSubmit || onCancel) && (
                <ModalBS.Footer className="no-borders">
                    {onCancel && (
                        <Button variant="outline-primary" className="no-borders" onClick={onCancel}>{cancelTitle}</Button>
                    )}
                    {onSubmit && (
                        <Button variant="primary" onClick={onSubmit} onReady={el => focusOnSubmit && el && el.focus()}>{submitTitle}</Button>
                    )}
                </ModalBS.Footer>
            )}
        </ModalBS>
    );
};
