import React from "react";
import { TableBuilder } from "./TableBuilder";
import { Form } from "_components/Base";

import { getObjectValueByMultilevelKey } from "_helpers";

/**
 * 
 * @param columns = array of Objects {header: "Table column name", prop: "_id", width: null, func: null} //example
 * @param onCheckBoxChange
 */
export const TableCheckboxed = ({ items, columns, selected, onCheckBoxChange, ...rest }) => {
    if (!items || items.length < 1) {
        return <></>
    }
    const widths = [18, ...columns.map(({ width }) => width)];
    const headers = [
        <Form.Check key='all-table-checkbox' type="checkbox" checked={selected.every(o => o)} onChange={onCheckBoxChange(-1)} aria-label="Checkbox" />,
        ...columns.map(({ header }) => header)];
    const rows = items.map((item, indx) => {
        return [
            <Form.Check key={`row-checkbox-${indx}`} type="checkbox" checked={selected[indx]} onChange={onCheckBoxChange(indx)} aria-label="Checkbox" />,
            ...columns.map(({ prop, func }) => {
                if (func) return func(item);
                let val = getObjectValueByMultilevelKey(item, prop);
                if (prop.endsWith('system') && val === "QBO") {
                    val = "QuickBooks Online"
                }
                return val !== undefined ? val.toString() : "";

            })
        ];
    });

    return (
        <TableBuilder
            headers={headers}
            rows={rows}
            widths={widths}
            className="table-striped"
            {...rest}
        />
    );

}