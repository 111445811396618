import React from "react";
import { history } from "_helpers";
import config from "_configs";

import { useIdleTimer } from 'react-idle-timer'
import { userActions } from "_actions";

export const IddleTimer = (props) => {
    const handleOnIdle = event => {
        console.log('User is Idle');
        history.push(config.clientUrls.LOGIN);
    }

    const handleOnActive = event => { }

    const handleOnAction = event => {
        // save to local storage current timestamp
        userActions.saveLastActionTimeStamp();
    }

    useIdleTimer({
        crossTab: { type: undefined, emitOnAllTabs: true },
        timeout: 1000 * 60 * config.IDDLE_SESSION_LOGOUT_TIMER, // milliseconds
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })
    return <></>;
}