import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const userService = {
	login,
	logout,
	forgot,
	reset,
	verifyEmail,
	register,
	finish_register,
	getProfile,
	getAll,
	getById,
	update,
	delete: _delete,

	initiate2FASetup,
	verify2FASetup,

	submitFeedback,

	getSecurityQuestion,
	answerSecurityQuestion,
};

function login(email, password, code) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email, password, code }),
	};

	return fetch(`${config.apiUrl}/auth/login`, requestOptions)
		.then(handleResponse);
}

function forgot(email) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email }),
	};

	return fetch(`${config.apiUrl}/auth/forgot`, requestOptions).then(
		handleResponse
	);
}

function verifyEmail(token) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(
		`${config.apiUrl}/auth/verifyEmail/${token}`,
		requestOptions
	).then(handleResponse);
}

function reset(token, newPassword, confirmNew) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ token, newPassword, confirmNew }),
	};

	return fetch(`${config.apiUrl}/auth/reset`, requestOptions).then(
		handleResponse
	);
}

function getSecurityQuestion(token) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include'
	};

	return fetch(`${config.apiUrl}/auth/question/${token}`, requestOptions).then(
		handleResponse
	);
}

function answerSecurityQuestion(token, answer) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		credentials: 'include',
		body: JSON.stringify({ answer }),
	};

	return fetch(`${config.apiUrl}/auth/question/${token}`, requestOptions).then(
		handleResponse
	);
}

function getProfile(include_tax = false) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	const suffix = "profile" + (include_tax ? "_with_tax" : "");
	return fetch(`${config.apiUrl}/user/${suffix}`, requestOptions)
		.then(handleResponse);
}

function logout() {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/auth/logout`, requestOptions).then(
		handleResponse
	);
}

function submitFeedback(feedback) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		credentials: 'include',
		body: JSON.stringify({ feedback }),
	};

	return fetch(`${config.apiUrl}/user/unsubscribe/feedback`, requestOptions).then(
		handleResponse
	);
}

function getAll() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	);
}

function register(user) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(user),
	};
	return fetch(`${config.apiUrl}/auth/register`, requestOptions).then(
		handleResponse
	);
}

function finish_register(user) {
	console.log(authHeader());
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		body: JSON.stringify(user),
	};
	return fetch(`${config.apiUrl}/auth/finish_register`, requestOptions).then(
		handleResponse
	);
}

function update(user) {
	const requestOptions = {
		method: "PUT",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		credentials: 'include',
		body: JSON.stringify(user),
	};

	return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(
		handleResponse
	);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	const requestOptions = {
		method: "DELETE",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	);
}

function initiate2FASetup() {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/auth/initiate-2fa-setup`, requestOptions)
		.then(handleResponse);
}

function verify2FASetup(code) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), "Content-Type": "application/json" },
		credentials: 'include',
		body: JSON.stringify({ code }),
	};

	return fetch(`${config.apiUrl}/auth/verify-2fa-setup`, requestOptions)
		.then(handleResponse);
}