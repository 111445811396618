import { genericService } from "_services";
import { alertActions, loadingAPIActions } from "_actions";
import { history } from "_helpers";
import config from "_configs";

export const genericActions = {
	getAllPlans,
	sendSupportEmail,
	sendConnect,
	getSecurityQuestions,
};

function sendConnect(requestBody) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return genericService.sendConnect(requestBody).then(
			({ result }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return result;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function getAllPlans() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return genericService.getAllPlans().then(
			({ result }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return result;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function sendSupportEmail() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());

		return genericService.sendSupportEmail().then(
			({ result }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(
					alertActions.success(
						"Thank you! We are processing your request and will get back to you as soon as possible!"
					)
				);
				history.push(config.clientUrls.HOME);
				return result;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function getSecurityQuestions() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return genericService.getSecurityQuestions().then(
			({ result }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return result;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}