import React from "react";
import { Dropdown } from "_components/Base";

export const MyDropdown = ({ items, title, className, variant }) => {
    if (!items || items.length < 1) {
        return <></>;
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant={variant ? variant : "none"} id="dropdown-basic" className={className ? className : undefined}>
                {title}
            </Dropdown.Toggle>

            <Dropdown.Menu popperConfig={{
                strategy: "fixed"
            }} style={{ margin: 0 }}>
                {items.map((item, indx) => (
                    <Dropdown.Item key={`dropdown-option-${indx}`} className="full-width" onClick={item.onClick}>
                        {item.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
