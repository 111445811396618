import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

export const billingService = {
	subscribeAndCharge,
	modifySubscription,
	resubscribe,
	cancelSubscription,
	getUserAccountSummary,
	addAlternativeCard,
	deleteCard,
	switchDefaultCard,
	getUserAccountSummaryPayments,
	startTrial,
	convertTrialToSubscription,
	chargeCard,
	getTaxes,
};

const POST_JSON_HEADER = { "Content-Type": "application/json" };

function subscribeAndCharge(amount, productId, tokenId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			amount,
			productId,
			tokenId,
		}),
	};
	return fetch(
		`${config.apiUrl}/billing/subscribe_and_charge`,
		requestOptions
	).then(handleResponse);
}


function chargeCard() {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/billing/pay`,
		requestOptions
	).then(handleResponse);
}

function startTrial() {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/billing/trial`, requestOptions
	).then(handleResponse);
}

function modifySubscription(productId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ productId }),
	};
	return fetch(
		`${config.apiUrl}/billing/modify_subscription`,
		requestOptions
	).then(handleResponse);
}

function resubscribe(productId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ productId }),
	};
	return fetch(
		`${config.apiUrl}/billing/resubscribe`,
		requestOptions
	).then(handleResponse);
}

function cancelSubscription() {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/billing/cancel_subscription`,
		requestOptions
	).then(handleResponse);
}

function getUserAccountSummary() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(`${config.apiUrl}/billing/summary`, requestOptions).then(
		handleResponse
	);
}

function getUserAccountSummaryPayments(page = null, limit = null) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	const paymentsUrl =
		page === null && limit === null
			? "/payments"
			: `/payments/${page}/${limit}`;
	return fetch(`${config.apiUrl}/billing${paymentsUrl}`, requestOptions).then(
		handleResponse
	);
}

function addAlternativeCard(tokenId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			tokenId,
		}),
	};
	return fetch(
		`${config.apiUrl}/billing/add_payment_method`,
		requestOptions
	).then(handleResponse);
}

function deleteCard(tokenId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			tokenId,
		}),
	};
	return fetch(
		`${config.apiUrl}/billing/delete_payment_method`,
		requestOptions
	).then(handleResponse);
}

function switchDefaultCard(tokenId) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			tokenId,
		}),
	};
	return fetch(
		`${config.apiUrl}/billing/switch_payment_method`,
		requestOptions
	).then(handleResponse);
}

function convertTrialToSubscription(productId, tokenId, toDelete = []) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ productId, tokenId, toDelete }),
	};
	return fetch(
		`${config.apiUrl}/billing/convert_trial_to_subscription`,
		requestOptions
	).then(handleResponse);
}

function getTaxes() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/billing/taxes`,
		requestOptions
	).then(handleResponse);
}
