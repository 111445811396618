import React from "react";
import Masonry from 'react-masonry-css'
import config from "./config";
import { history } from "_helpers";
import { AccountBalance, Adjust, BeachAccess, CloudCircle, DirectionsBike } from '@material-ui/icons';
import { H1 } from "_components/Base"

import "_css/knowhow.css";
const styles = {
    largeIcon: {
        width: 120,
        height: 120,
        color: "var(--grey-0)"
    },
    image: {
        height: 120,
        maxWidth: 120,
    }
};

const IconsLoop = [AccountBalance, Adjust, BeachAccess, CloudCircle, DirectionsBike];
const IconCmp = ({ Icon: Component, ...props }) => <Component {...props} />


const GridElement = ({ cfg, index, ...props }) => {
    let image = null;
    if (cfg.img) {
        image = <img style={styles.image} src={cfg.img} alt='KnowHowImage' />
    } else {
        image = <IconCmp Icon={IconsLoop[index % IconsLoop.length]} style={styles.largeIcon} />
    }
    return <div className="d-flex flex-column h-center mb-0" onClick={(e) => { history.push(cfg.url) }}>
        <div>
            {image}
        </div>
        <h4 className='text-center'>{cfg.title}</h4>
        {cfg.description &&
            <p className="text-muted">{cfg.description}</p>
        }
    </div >
}

export default function KnowHowPage(props) {
    return <>
        <H1>The "KnowHow" landing page</H1>
        <Masonry
            breakpointCols={3}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {
                Object
                    .values(config.pages || {})
                    .sort((o1, o2) => o1.order - o2.order)
                    .map((cfg, indx) =>
                        <GridElement
                            key={`grid-elm-${indx}`}
                            cfg={cfg} index={indx} />
                    )
            }
        </Masonry>
    </>
}