import React from "react";

export const Icon = ({ name, className = "", ...props }) => {
    return <i className={`material-icons ${className}`} {...props} > {name}</i >
}

/**
 * 
 * Everything is taken from material-io:
 * https://material.io/resources/icons/?style=baseline
 * 
 * @TODO Rename to proper icon naming
 */

export const TaskIcon = (props) => <Icon name="pending_actions" {...props} />
export const MenuBookIcon = (props) => <Icon name="menu_book" {...props} />
export const AccountBalanceIcon = (props) => <Icon name="account_balance" {...props} />
export const ContentCopyIcon = (props) => <Icon name="content_copy" {...props} />
export const BlockIcon = (props) => <Icon name="block" {...props} />
export const NoteAddIcon = (props) => <Icon name="note_add" {...props} />
export const QueryBuilderIcon = (props) => <Icon name="query_builder" {...props} />
export const ShoppingCartIcon = (props) => <Icon name="shopping_cart" {...props} />
export const SwapVertIcon = (props) => <Icon name="swap_vert" {...props} />
export const PhotoCameraIcon = (props) => <Icon name="photo_camera" {...props} />
export const CloudUploadIcon = (props) => <Icon name="cloud_upload" {...props} />
export const BookmarkIcon = (props) => <Icon name="bookmark" {...props} />
export const ErrorOutlineIcon = (props) => <Icon name="error_outline" {...props} />
export const ThumbDownIcon = (props) => <Icon name="thumb_down" {...props} />
export const SpeedIcon = (props) => <Icon name="speed" {...props} />
export const VerifiedUserIcon = (props) => <Icon name="verified_user" {...props} />
export const TodayIcon = (props) => <Icon name="today" {...props} />
export const RestoreIcon = (props) => <Icon name="restore" {...props} />
export const OpenWithIcon = (props) => <Icon name="open_with" {...props} />
export const FilterAltIcon = (props) => <Icon name="filter_alt" {...props} />
export const LocationOnIcon = (props) => <Icon name="location_on" {...props} />
export const LoginIcon = (props) => <Icon name="login" {...props} />
export const AddCircleIcon = (props) => <Icon name="add_circle" {...props} />
export const MoreHorizIcon = (props) => <Icon name="more_horiz" {...props} />
export const FlagIcon = (props) => <Icon name="flag" {...props} />
export const RequestQuoteIcon = (props) => <Icon name="request_quote" {...props} />
export const HelpIcon = (props) => <Icon name="help" {...props} />
export const FolderOpenIcon = (props) => <Icon name="folder_open" {...props} />
export const RestorePageIcon = (props) => <Icon name="restore_page" {...props} />
export const EmailIcon = (props) => <Icon name="email" {...props} />
export const FolderIcon = (props) => <Icon name="folder" {...props} />
export const BuildIcon = (props) => <Icon name="build" {...props} />
export const ErrorIcon = (props) => <Icon name="error" {...props} />
export const VerifiedIcon = (props) => <Icon name="verified" {...props} />
export const DateRangeIcon = (props) => <Icon name="date_range" {...props} />
export const FavoriteBorderIcon = (props) => <Icon name="favorite_border" {...props} />
export const MoreVertIcon = (props) => <Icon name="more_vert" {...props} />
export const CreateNewFolderIcon = (props) => <Icon name="create_new_folder" {...props} />
export const LockOpenIcon = (props) => <Icon name="lock_open" {...props} />
export const InsertPhotoIcon = (props) => <Icon name="insert_photo" {...props} />
export const LanguageIcon = (props) => <Icon name="language" {...props} />
export const ReportProblemIcon = (props) => <Icon name="report_problem" {...props} />
export const HighlightOffIcon = (props) => <Icon name="highlight_off" {...props} />
export const LockIcon = (props) => <Icon name="lock" {...props} />
export const CloudDownloadIcon = (props) => <Icon name="cloud_download" {...props} />
export const DoneIcon = (props) => <Icon name="done" {...props} />
export const SwapHorizIcon = (props) => <Icon name="swap_horiz" {...props} />
export const AddBoxIcon = (props) => <Icon name="add_box" {...props} />
export const HelpOutlightIcon = (props) => <Icon name="help_outline" {...props} />
export const SearchIcon = (props) => <Icon name="search" {...props} />
export const ForumIcon = (props) => <Icon name="forum" {...props} />
export const PermMediaIcon = (props) => <Icon name="perm_media" {...props} />
export const InsertChartIcon = (props) => <Icon name="insert_chart" {...props} />
export const HomeIcon = (props) => <Icon name="home" {...props} />
export const BookmarkBorderIcon = (props) => <Icon name="bookmark_border" {...props} />
export const SupportIcon = (props) => <Icon name="support" {...props} />
export const ImageNotSupportedIcon = (props) => <Icon name="image_not_supported" {...props} />
export const InsertChartOutlinedIcon = (props) => <Icon name="insert_chart_outlined" {...props} />
export const CheckCircleIcon = (props) => <Icon name="check_circle" {...props} />
export const DeleteIcon = (props) => <Icon name="delete" {...props} />
export const OpenInFullIcon = (props) => <Icon name="open_in_full" {...props} />
export const CreateIcon = (props) => <Icon name="create" {...props} />
export const VerticalAlignTopIcon = (props) => <Icon name="vertical_align_top" {...props} />
export const PaymentIcon = (props) => <Icon name="payment" {...props} />
export const AddIcon = (props) => <Icon name="add" {...props} />
export const SettingsIcon = (props) => <Icon name="settings" {...props} />
export const CloudDoneIcon = (props) => <Icon name="cloud_done" {...props} />
export const CachedIcon = (props) => <Icon name="cached" {...props} />
export const ThumbUpIcon = (props) => <Icon name="thumb_up" {...props} />
export const InsertDriveFileIcon = (props) => <Icon name="insert_drive_file" {...props} />
export const OpenInNewIcon = (props) => <Icon name="open_in_new" {...props} />
export const VerticalAlignBottomIcon = (props) => <Icon name="vertical_align_bottom" {...props} />
export const InfoIcon = (props) => <Icon name="info" {...props} />
export const AddAlertIcon = (props) => <Icon name="add_alert" {...props} />
export const FavoriteIcon = (props) => <Icon name="favorite" {...props} />
export const LinkIcon = (props) => <Icon name="link" {...props} />


export const FlowerIcon = (props) => <img {...props} src="/assets/icons/flower-icon.svg" alt="flower-icon" />
export const SaveTimeIcon = (props) => <img {...props} src="/assets/icons/save-time-icon.svg" alt="save-time-icon" />
export const SaveFilesIcon = (props) => <img {...props} src="/assets/icons/save-files-icon.svg" alt="save-files-icon" />
export const TrustworthyIcon = (props) => <img {...props} src="/assets/icons/trustworthy-icon.svg" alt="trustworthy-icon" />


// flags are taken from https://www.alt-codes.net/flags
export const FlagUSIcon = (props) => <span>&#127482;&#127480;</span>
export const FlagCAIcon = (props) => <span>&#127464;&#127462;</span>
export const FlagNZIcon = (props) => <span>&#127475;&#127487;</span>
export const FlagAUIcon = (props) => <span>&#127462;&#127482;</span>
export const FlagUKIcon = (props) => <span>&#127468;&#127463;</span>
export const FlagEUIcon = (props) => <span>&#127466;&#127482;</span>


const currency2FlagIcon = {
    USA: <FlagUSIcon />,
    CAN: <FlagCAIcon />,
    NZL: <FlagNZIcon />,
    AUS: <FlagAUIcon />,
    GBR: <FlagUKIcon />,
    EUR: <FlagEUIcon />,
    usd: <FlagUSIcon />,
    cad: <FlagCAIcon />,
    nzd: <FlagNZIcon />,
    aud: <FlagAUIcon />,
    gbp: <FlagUKIcon />,
    eur: <FlagEUIcon />,
}

export const FlagIconMappingObject = (currency) => {
    return currency2FlagIcon[currency] || currency2FlagIcon[currency.toLowerCase()];
}
