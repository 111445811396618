import { alertConstants } from "_constants";
import { toast } from 'react-toastify';
const persistentErrorToastId = 'toast-persistent-error-id';

export const alertActions = {
	success,
	warning,
	error,
	persistentError,
	alert,
	confirm,
	clearPersistentError,
	clearError,
	clearSuccess,
	clearWarning,
	clearAll,
	clearConfirmation,
};

function success(messageObj) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	const id = toast.success(message);
	return { type: alertConstants.SUCCESS, message, id };
}

function error(messageObj, autoClose = 5000) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	const id = toast.error(message, { autoClose });
	return { type: alertConstants.ERROR, message, id };
}

function persistentError(messageObj, autoClose = false) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	const id = toast.error(message, { autoClose, toastId: persistentErrorToastId });
	return { type: alertConstants.PERSISTENT_ERROR, message, id };
}

function warning(messageObj, autoClose = 5000) {
	let message = messageObj;
	if (typeof messageObj === "object") {
		message = messageObj.message;
	}
	const id = toast.warning(message, { autoClose });
	return { type: alertConstants.WARNING, message, id };
}

function alert(messageObj) {
	let message = messageObj;
	if (messageObj.type && messageObj.error) {
		message = messageObj.error;
		if (messageObj.type === "warning") {
			return warning(message);
		} else if (messageObj.type === "success") {
			return success(message);
		}
	}
	return error(message.error);
}

function confirm(message, successFunction) {
	return { type: alertConstants.CONFIRM, message, successFunction };
}

function clearPersistentError() {
	toast.dismiss(persistentErrorToastId);
	return { type: alertConstants.CLEAR_PERSISTENT_ERROR };
}

function clearError() {
	return { type: alertConstants.CLEAR_ERROR };
}

function clearSuccess() {
	return { type: alertConstants.CLEAR_SUCCESS };
}

function clearWarning() {
	return { type: alertConstants.CLEAR_WARNING };
}

function clearConfirmation() {
	return { type: alertConstants.CLEAR_CONFIRMATION };
}

function clearAll() {
	toast.dismiss();
	return { type: alertConstants.CLEAR_ALL };
}
