import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

const POST_JSON_HEADER = { "Content-Type": "application/json" };

export const genericService = {
	getAllPlans,
	sendSupportEmail,
	sendConnect,
	getSecurityQuestions,
};

function sendConnect(requestBody) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: requestBody,
	};

	return fetch(`${config.apiUrl}/generic/connect`, requestOptions).then(
		handleResponse
	);
}

function getAllPlans() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/generic/plans`, requestOptions).then(
		handleResponse
	);
}

function getSecurityQuestions() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	return fetch(`${config.apiUrl}/generic/questions`, requestOptions).then(
		handleResponse
	);
}

function sendSupportEmail(requestBody) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: requestBody,
	};

	return fetch(`${config.apiUrl}/generic/send_support_email`, requestOptions).then(
		handleResponse
	);
}
