import { userConstants } from "_constants";

function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

/**
 * My own implementation of "loading" flag management
 * */
export function users(state = {}, action) {
	const stateImpl = usersImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function usersImpl(state = {}, action) {
	switch (action.type) {
		// case userConstants.CLEAR_ALL:
		// 	return {};

		case userConstants.USERS_GET_ALL_REQUEST:
			return {
				loading: true,
			};
		case userConstants.USERS_GET_ALL_SUCCESS:
			return {
				items: action.users,
			};
		case userConstants.USERS_GET_ALL_FAILURE:
			return {
				error: action.error,
			};

		case userConstants.DELETE_REQUEST:
			// add 'deleting:true' property to user being deleted
			return {
				...state,
				items: state.items.map((user) =>
					user.id === action.id ? { ...user, deleting: true } : user
				),
			};
		case userConstants.DELETE_SUCCESS:
			// remove deleted user from state
			return {
				items: state.items.filter((user) => user.id !== action.id),
			};
		case userConstants.DELETE_FAILURE:
			// remove 'deleting:true' property and add 'deleteError:[error]' property to user
			return {
				...state,
				items: state.items.map((user) => {
					if (user.id === action.id) {
						// make copy of user without 'deleting:true' property
						const { deleting, ...userCopy } = user;
						// return copy of user with 'deleteError:[error]' property
						return { ...userCopy, deleteError: action.error };
					}

					return user;
				}),
			};
		default:
			return state;
	}
}
