import { history } from "_helpers";
import config from "_configs";

export function validateEmail(email) {
	const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return emailRegexp.test(email);
}

export async function handleResponse(response) {
	try {
		return response
			.json()
			// //for tests
			// return response.text().then(text => {
			// 	console.log(text);
			// 	return JSON.parse(text)
			// })
			.then((data) => {
				if (!response.ok) {
					if (response.status === 401) {
						// window.location.reload();
						history.push(config.clientUrls.LOGIN);
					} else if (response.status === 426) {
						history.push(config.clientUrls.SUBSCRIPTION);
					} else if (response.status === 402) {
						history.push(config.clientUrls.USER_ACCOUNT_SUMMARY); // @TODO change to the billing page when it exists
					} else if (response.status >= 500) {
						return Promise.reject({ reason: "NETWORK_PROBLEM" });
					}
					return Promise.reject({ response_status: response.status, ...data });
				}
				return data;
			})
			.catch((err) => {
				console.log(err);
				if (!err.error) {
					history.push(config.clientUrls.MOKAFILE__NETWORK__PROBLEM);
					const e = { message: "Can't connect to the server" };
					throw e;
				} else {
					throw err;
				}
			});
	} catch (error) {
		console.log(error);
	}
}

export function handleResponseCompanyFallback(companyId) {
	return async (response) => {
		try {
			return response
				.json()
				// //for tests
				// return response.text().then(text => {
				// 	console.log(text);
				// 	return JSON.parse(text)
				// })
				.then((data) => {
					if (!response.ok) {
						if (response.status === 401) {
							// window.location.reload();
							history.push(config.clientUrls.LOGIN);
						} else if (response.status === 426) {
							history.push(config.clientUrls.SUBSCRIPTION);
						} else if (response.status === 412) {
							history.push(config.clientUrls.MOKAFILE__COMPANY_HOME(companyId));
						} else if (response.status === 402) {
							history.push(config.clientUrls.USER_ACCOUNT_SUMMARY); // @TODO change to the billing page when it exists
						} else if (response.status >= 500) {
							return Promise.reject({ reason: "NETWORK_PROBLEM" });
						}
						return Promise.reject({ response_status: response.status, ...data });
					}
					return data;
				})
				.catch((err) => {
					console.log(err);
					if (!err.error) {
						history.push(config.clientUrls.MOKAFILE__NETWORK__PROBLEM);
						const e = { message: "Can't connect to the server" };
						throw e;
					} else {
						throw err;
					}
				});
		} catch (error) {
			console.log(error);
		}
	}
}