import { billingConstants, userConstants } from "_constants";
import { billingService } from "_services";
import { alertActions, loadingAPIActions, userActions } from ".";

import { history } from "_helpers";
import config from "_configs";

export const billingActions = {
	subscribeAndCharge,
	getUserAccountSummary,
	getUserAccountSummaryPayments,
	addAlternativeCard,
	deleteCard,
	switchDefaultCard,
	modifySubscription,
	cancelSubscription,
	resubscribe,
	startTrial,
	convertTrialToSubscription,
	chargeCard,
	getTaxes,
};

function chargeCard() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.chargeCard().then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function startTrial() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.startTrial().then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				history.push(config.clientUrls.USER_ACCOUNT_SUMMARY);
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function cancelSubscription() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return billingService.cancelSubscription().then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				dispatch(success());
				history.push(config.clientUrls.USER_ACCOUNT_SUMMARY);
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: billingConstants.CANCEL_SUBSCRIPTION_REQUEST };
	}
	function success() {
		return { type: billingConstants.CANCEL_SUBSCRIPTION_SUCCESS };
	}
	function failure(error) {
		return { type: billingConstants.CANCEL_SUBSCRIPTION_FAILURE, error };
	}
}

function resubscribe(productId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.resubscribe(productId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				history.push(config.clientUrls.USER_ACCOUNT_SUMMARY);
				return true;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function modifySubscription(productId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return billingService.modifySubscription(productId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success(response.message));
				dispatch(success());
				history.push(config.clientUrls.USER_ACCOUNT_SUMMARY);
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: billingConstants.MODIFY_SUBSCRIPTION_REQUEST };
	}
	function success() {
		return { type: billingConstants.MODIFY_SUBSCRIPTION_SUCCESS };
	}
	function failure(error) {
		return { type: billingConstants.MODIFY_SUBSCRIPTION_FAILURE, error };
	}
}

function subscribeAndCharge(amount, productId, tokenId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		dispatch(request());

		return billingService.subscribeAndCharge(amount, productId, tokenId).then(
			(response) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				dispatch(alertActions.success("Subscribed successfully"));
				dispatch(success());
				dispatch(userActions.changeSubscription.success(response.subscription));
				history.push(config.clientUrls.USER_ACCOUNT_SUMMARY);
				return true;
			},
			(error) => {
				dispatch(failure(error.error));
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};

	function request() {
		return { type: billingConstants.SUBSCRIBE_AND_CHARGE_REQUEST };
	}
	function success() {
		return { type: billingConstants.SUBSCRIBE_AND_CHARGE_SUCCESS };
	}
	function failure(error) {
		return { type: billingConstants.SUBSCRIBE_AND_CHARGE_FAILURE, error };
	}
}

function getUserAccountSummary() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.getUserAccountSummary().then(
			(response) => {
				dispatch(success(response.user));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return response;
			},
			(error) => {

				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
	// add user to the store
	function success(user) {
		let oldUser = {};
		try {
			oldUser = JSON.parse(localStorage.getItem("kalestack__user")) || {};
		} catch (err) { }
		localStorage.setItem("kalestack__user", JSON.stringify({ ...oldUser, ...user }));
		return { type: userConstants.USERS_GET_PROFILE_SUCCESS, user };
	}
}

function getUserAccountSummaryPayments(
	payments_page = null,
	payments_limit = null
) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService
			.getUserAccountSummaryPayments(payments_page, payments_limit)
			.then(
				({ payments }) => {
					dispatch(loadingAPIActions.stopLoadingAPI());
					return payments
				},
				(error) => {
					dispatch(alertActions.alert(error));
					dispatch(loadingAPIActions.stopLoadingAPI());
					throw error;
				}
			);
	};
}

function addAlternativeCard(tokenId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.addAlternativeCard(tokenId).then(
			({ card, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return card;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function deleteCard(tokenId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.deleteCard(tokenId).then(
			({ cardId, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return cardId;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function switchDefaultCard(tokenId) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.switchDefaultCard(tokenId).then(
			({ cardId, message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
				return cardId;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}

function convertTrialToSubscription(productId, tokenId, toDelete) {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.convertTrialToSubscription(productId, tokenId, toDelete).then(
			({ message }) => {
				dispatch(alertActions.success(message));
				dispatch(loadingAPIActions.stopLoadingAPI());
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
			}
		);
	};
}

function getTaxes() {
	return (dispatch) => {
		dispatch(loadingAPIActions.startLoadingAPI());
		return billingService.getTaxes().then(
			({ tax }) => {
				dispatch(loadingAPIActions.stopLoadingAPI());
				return tax;
			},
			(error) => {
				dispatch(alertActions.alert(error));
				dispatch(loadingAPIActions.stopLoadingAPI());
				throw error;
			}
		);
	};
}