import React from "react";
import { Route } from "_components";
import config from "../config";

export async function getKnowHowRoutes() {
    // @note: don't know why, but regexp can't be consumed from variable
    const context = require.context('./', false, /\.(jsx)$/); // |js
    const promises = context.keys().map((key, indx) => {
        const pageName = key.replace(/^\.\//, '').replace(/\.(jsx)$/, '');
        return import(`./${pageName}`).then(module =>
            <Route
                key={`know-how-route-${indx}`}
                path={config.pages[pageName].url}
                component={module.default} />
        )
    })
    const routes = await Promise.all(promises);
    return routes;
}