import React from "react";

import { css } from "@emotion/core";
// import ClockLoader from "react-spinners/ClockLoader"; // to reduce bundle size
import Loader from "react-spinners/RingLoader";

export const FreezeScreenLoader = ({ loading }) => {
    // top: 20%;
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: white;
    `;

    return (
        <>
            {loading && (
                <div className="sweet-loading h-center">
                    <Loader
                        css={override}
                        size={60} // 150
                        color={"#cccccc"}
                        loading={loading}
                    />
                </div>
            )}
        </>
    );
}