import { PEBookkeepingConstants, bookkeepingConstants, userConstants, ALBookkeepingConstants } from "_constants";
const initialState = {
	prepaidExpenses: [],
	accruedLiabilities: [],
	companies: [],
	company: {
		systemConnect: {},
		accounts: [],
		// prepaidExpenses: {},
		peManager: {},
		dataToBeRecognized: {},

		companyId: "",
		companyID: "",
		companyName: "",
		createdAt: "",
		system: "",
		bookkeeping: {},
		isDefault: false,
		localPEBookCloseDate: "",
		localALBookCloseDate: "",
		isReconnectionNeeded: false,
		subscription: {},
	},
	states: {},
};

// super simplification
function compareObjs(obj1, obj2) {
	for (let o2 in obj2) {
		if (obj1[o2] !== obj2[o2]) {
			return false;
		}
	}
	return true;
}

function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

/**
 * My own implementation of "loading" flag management
 * */
export function bookkeeping(state = initialState, action) {
	const stateImpl = bookkeepingImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function bookkeepingImpl(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return initialState;
		case bookkeepingConstants.QBO_CONNECT_REQUEST:
		case bookkeepingConstants.XERO_CONNECT_REQUEST:
			return Object.assign({}, state, {
				company: {
					...state.company,
					systemConnect: { connecting: true },
				},
			});
		case bookkeepingConstants.QBO_CONNECT_SUCCESS:
		case bookkeepingConstants.XERO_CONNECT_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					systemConnect: { connected: true },
				},
			});
		case bookkeepingConstants.QBO_CONNECT_FAILURE:
		case bookkeepingConstants.XERO_CONNECT_FAILURE:
		case bookkeepingConstants.QBO_CALLBACK_FAILURE:
		case bookkeepingConstants.XERO_CALLBACK_FAILURE:
			return Object.assign({}, state, {
				error: action.error,

				company: {
					...state.company,
					systemConnect: {},
				},
			});

		case bookkeepingConstants.QBO_CALLBACK_REQUEST:
		case bookkeepingConstants.XERO_CALLBACK_REQUEST:
			return Object.assign({}, state, {
				company: {
					...state.company,
					systemConnect: { receiving: true },
				},
			});
		case bookkeepingConstants.QBO_CALLBACK_SUCCESS:
		case bookkeepingConstants.XERO_CALLBACK_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					systemConnect: { received: true },
					companyId: action.companyId,
					isReconnectionNeeded: action.isReconnectionNeeded,
				},
			});

		case bookkeepingConstants.QBO_DISCONNECT_REQUEST:
		case bookkeepingConstants.XERO_DISCONNECT_REQUEST:
			return state;

		case bookkeepingConstants.QBO_DISCONNECT_SUCCESS:
		case bookkeepingConstants.XERO_DISCONNECT_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					isReconnectionNeeded: action.isReconnectionNeeded,
					companyId: action.companyId,
				},
			});

		case bookkeepingConstants.QBO_DISCONNECT_FAILURE:
		case bookkeepingConstants.XERO_DISCONNECT_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case bookkeepingConstants.GET_COPMANIES_LIST_REQUEST:
			return state;
		// return Object.assign({}, state, {
		// 	companies: { loading: true },
		// });
		case bookkeepingConstants.GET_COPMANIES_LIST_SUCCESS:
			return Object.assign({}, state, {
				companies: action.companies,
			});
		// return Object.assign({}, state, {
		// 	companies: {
		// 		loaded: true,
		// 		companies: action.companies,
		// 	},
		// });
		case bookkeepingConstants.GET_COPMANIES_LIST_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				companies: [],
			});

		case bookkeepingConstants.GET_ACCOUNT_LIST_REQUEST:
			return state;
		// return Object.assign({}, state, {
		// 	company: {
		// 		...state.company,
		// 		accounts: { loading: true },
		// 	},
		// });
		case bookkeepingConstants.GET_ACCOUNT_LIST_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					accounts: action.accounts,
				},
			});
		// return Object.assign({}, state, {
		// 	company: {
		// 		...state.company,
		// 		accounts: { loaded: true, accounts: action.accounts },
		// 	},
		// });
		case bookkeepingConstants.GET_ACCOUNT_LIST_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				company: {
					...state.company,
					accounts: [],
				},
			});
		case PEBookkeepingConstants.GET_PE_LIST_REQUEST:
			return Object.assign({}, state, {
				prepaidExpenses: [],
			});
		case ALBookkeepingConstants.GET_AL_LIST_REQUEST:
			return Object.assign({}, state, {
				accruedLiabilities: [],
			});
		// return Object.assign({}, state, {
		// 	company: {
		// 		...state.company,
		// 		prepaidExpenses: { loading: true },
		// 	},
		// });
		case PEBookkeepingConstants.GET_PE_LIST_SUCCESS:
			return Object.assign({}, state, {
				prepaidExpenses: action.prepaidExpenses,
			});
		case ALBookkeepingConstants.GET_AL_LIST_SUCCESS:
			return Object.assign({}, state, {
				accruedLiabilities: action.accruedLiabilities,
			});
		case PEBookkeepingConstants.GET_PE_LIST_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				prepaidExpenses: [],
			});
		case ALBookkeepingConstants.GET_AL_LIST_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				accruedLiabilities: [],
			});

		case PEBookkeepingConstants.EXPORT_PE_LIST_REQUEST:
		case ALBookkeepingConstants.EXPORT_AL_LIST_REQUEST:
			return Object.assign({}, state, {});
		case PEBookkeepingConstants.EXPORT_PE_LIST_SUCCESS:
		case ALBookkeepingConstants.EXPORT_AL_LIST_SUCCESS:
			return Object.assign({}, state, {});
		case PEBookkeepingConstants.EXPORT_PE_LIST_FAILURE:
		case ALBookkeepingConstants.EXPORT_AL_LIST_FAILURE:
			return Object.assign({}, state, { error: action.error });

		case PEBookkeepingConstants.SET_PE_ACCOUNT_REQUEST:
		case ALBookkeepingConstants.SET_AL_ACCOUNT_REQUEST:
			return state;
		case ALBookkeepingConstants.SET_AL_ACCOUNT_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					bookkeeping: {
						alAccountSelected: true,
						alAccountName: action.alAccountName,
					},
				},
			});
		case PEBookkeepingConstants.SET_PE_ACCOUNT_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					bookkeeping: {
						peAccountSelected: true,
						peAccountName: action.peAccountName,
					},
				},
			});
		case PEBookkeepingConstants.SET_PE_ACCOUNT_FAILURE:
		case ALBookkeepingConstants.SET_AL_ACCOUNT_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case PEBookkeepingConstants.ADD_PE_REQUEST:
			return Object.assign({}, state, {
				company: {
					...state.company,
					peManager: {},
				},
			});
		case PEBookkeepingConstants.ADD_PE_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					peManager: {
						last_prepaid_expense: {
							schedule: action.schedule,
							journal: action.journal,
						},
					},
				},
			});
		case PEBookkeepingConstants.ADD_PE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,

				company: {
					...state.company,
					peManager: {},
				},
			});
		case PEBookkeepingConstants.GET_PE_SCHEDULE_REQUEST:
			return state;
		// return Object.assign({}, state, {
		// 	company: {
		// 		...state.company,
		// 		peManager: { loadingPESchedule: true },
		// 	},
		// });
		case PEBookkeepingConstants.GET_PE_SCHEDULE_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					peManager: {
						last_prepaid_expense: {
							schedule: action.schedule,
							journal: action.journal,
						},
					},
				},
			});
		case PEBookkeepingConstants.GET_PE_SCHEDULE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				company: {
					...state.company,
					peManager: {},
				},
			});

		case PEBookkeepingConstants.DELETE_PE_SCHEDULE_REQUEST:
		case PEBookkeepingConstants.DELETE_PE_SCHEDULE_SUCCESS:
			return state;
		case PEBookkeepingConstants.DELETE_PE_SCHEDULE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case PEBookkeepingConstants.DELETE_PES_INLINE:
			return Object.assign({}, state, {
				prepaidExpenses:
					!state.prepaidExpenses || state.prepaidExpenses.length < 1
						? []
						: state.prepaidExpenses.filter(
							(obj) => !compareObjs(obj, action.query)
						),
			});

		case PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_REQUEST:
			return Object.assign({}, state, {
				company: {
					...state.company,
					dataToBeRecognized: {},
				},
			});

		case PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					dataToBeRecognized: {
						dataToBeRecognized: action.dataToBeRecognized,
						dates: action.dates,
						warnings: action.warnings,
						loaded: true,
					},
				},
			});
		case PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
				company: {
					...state.company,
					dataToBeRecognized: {},
				},
			});

		case PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_REQUEST:
			return state;
		case PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					localPEBookCloseDate: action.localPEBookCloseDate,
				},
			});
		case ALBookkeepingConstants.CONFIRM_RECOGNIZE_AL_DATA_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					localALBookCloseDate: action.localALBookCloseDate,
				},
			});
		case PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA_FAILURE:
			return Object.assign({}, state, {
				error: action.error,

				company: {
					...state.company,
					dataToBeRecognized: {},
				},
			});

		case PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_REQUEST:
			return state;
		case PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					dataToBeRecognized: {},
					localPEBookCloseDate: action.localPEBookCloseDate,
				},
			});
		case ALBookkeepingConstants.ROLLBACK_RECOGNIZE_AL_DATA_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,
					dataToBeRecognized: {},
					localALBookCloseDate: action.localALBookCloseDate,
				},
			});
		case PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case bookkeepingConstants.GET_COMPANY_DETAILS_REQUEST:
			return state;
		case bookkeepingConstants.GET_COMPANY_DETAILS_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...state.company,

					companyId: action.company._id,
					companyID: action.company.companyID,
					companyName: action.company.companyName,
					createdAt: action.company.createdAt,
					system: action.company.system,
					bookkeeping: action.company.bookkeeping,
					isDefault: action.company.isDefault,
					localPEBookCloseDate: action.company.localPEBookCloseDate,
					localALBookCloseDate: action.company.localALBookCloseDate,
					isReconnectionNeeded: action.company.isReconnectionNeeded,
					subscription: action.company.subscription,
				},
			});
		case bookkeepingConstants.GET_COMPANY_DETAILS_FAILURE:
			return Object.assign({}, state, {
				error: action.error,

				company: {
					...initialState.company, // USING INITIAL STATE
				},
			});

		case bookkeepingConstants.CREATE_COMPANY_REQUEST:
		case bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_REQUEST:
			return Object.assign({}, state, {
				company: {
					...initialState.company, // USING INITIAL STATE
				},
			});
		case bookkeepingConstants.CREATE_COMPANY_SUCCESS:
		case bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...initialState.company, // USING INITIAL STATE
					companyId: action.companyId,
				},
			});
		case bookkeepingConstants.CREATE_COMPANY_FAILURE:
		case bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY_FAILURE:
			return Object.assign({}, state, {
				error: action.error,

				company: {
					...initialState.company, // USING INITIAL STATE
				},
			});
		case bookkeepingConstants.DELETE_COMPANY_REQUEST:
			return state;
		case bookkeepingConstants.DELETE_COMPANY_SUCCESS:
			return Object.assign({}, state, {
				company: {
					...(state.company.companyId === action.companyId
						? initialState.company
						: state.company),
				},
				companies:
					state.companies && state.companies.length > 0
						? state.companies.filter(
							(company) => company._id !== action.companyId
						)
						: [],
			});
		case bookkeepingConstants.DELETE_COMPANY_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case bookkeepingConstants.MAKE_COMPANY_DEFAULT_REQUEST:
			return state;
		case bookkeepingConstants.MAKE_COMPANY_DEFAULT_SUCCESS:
			return Object.assign({}, state, {
				// if dealing with standalone company - fix it
				company: {
					...state.company, // USING INITIAL STATE
					isDefault: state.company.companyId === action.defaultCompanyId,
				},
				// if dealing with companylist fix those
				companies:
					state.companies && state.companies.length > 0
						? state.companies.map((company) => {
							return {
								...company,
								isDefault: company._id === action.defaultCompanyId,
							};
						})
						: [],
			});
		case bookkeepingConstants.MAKE_COMPANY_DEFAULT_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		default:
			return state;
	}
}
