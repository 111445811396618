import React, { useEffect, createRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Button = ({
    children,
    className = "",
    type = "button", // To avoid form submittion on click (use submit otherwise)
    style,
    disabled,
    disabledTooltip,
    isLink,
    variant = "primary",
    onReady,
    ...props
}) => {
    const btnRef = createRef();
    useEffect(() => {
        if (onReady) {
            onReady(btnRef.current);
        }
        // @ATTENTION: DISABLING ESLING WARNINGS!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let classNames = [];
    if (isLink) {
        classNames.push("linkButton btn-none");
    } else {
        classNames.push("btn");
        if (variant && variant.length > 0) {
            classNames.push(`btn-${variant}`);
        }
        // classNames.push("btn-sized")
    }
    classNames.push(className);
    props.className = classNames.join(" ");

    if (disabled && disabledTooltip) {
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{disabledTooltip}</Tooltip>}>
            <span className="d-inline-block full-width">
                <button type={type} ref={btnRef} {...props} style={{ pointerEvents: 'none', ...style }}>{children}</button>
            </span>
        </OverlayTrigger>
    }
    return <button type={type} ref={btnRef} style={style} {...props}>{children}</button>;
};