import React from "react";
import Octicon, { Plus, Dash } from "@primer/octicons-react";
import { Button } from "_components/Base";

const ButtonAdd = ({ onClick, width = "35px", ...rest }) => (
	<Button
		variant="outline-secondary"
		style={{ width: width }}
		onClick={onClick}
	>
		<Octicon icon={Plus} />
	</Button>
);

const ButtonDelete = ({ onClick, width = "35px", ...rest }) => (
	<Button
		variant="outline-secondary"
		style={{ width: width }}
		onClick={onClick}
	>
		<Octicon icon={Dash} />
	</Button>
);

export { ButtonAdd, ButtonDelete };
