import { billingConstants, userConstants } from "_constants";
const initialState = {
	summary: {},
	states: {},
};

function stateFlagManager(stateImpl, type) {
	const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
	if (!matches) return stateImpl;
	const [, requestName, requestState] = matches;
	let flagData = {
		["loading_" + requestName]: requestState === "REQUEST",
		["loaded_" + requestName]: requestState === "SUCCESS",
	};
	return Object.assign({}, stateImpl, {
		states: {
			...stateImpl.states,
			...flagData,
		},
	});
}

/**
 * My own implementation of "loading" flag management
 * */
export function billing(state = initialState, action) {
	const stateImpl = billingImpl(state, action);
	return stateFlagManager(stateImpl, action.type);
}

function billingImpl(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return initialState;
		case billingConstants.CANCEL_SUBSCRIPTION_REQUEST:
		case billingConstants.MODIFY_SUBSCRIPTION_REQUEST:
		case billingConstants.SUBSCRIBE_AND_CHARGE_REQUEST:
			return state;
		case billingConstants.MODIFY_SUBSCRIPTION_SUCCESS:
			return Object.assign({}, state, {
				summary: {
					...state.summary,
					subscription: { ...state.summary.subscription, requestedUnsubscribe: true }
				},
			});
		case billingConstants.CANCEL_SUBSCRIPTION_SUCCESS:
		case billingConstants.SUBSCRIBE_AND_CHARGE_SUCCESS:
			return state;
		case billingConstants.MODIFY_SUBSCRIPTION_FAILURE:
		case billingConstants.CANCEL_SUBSCRIPTION_FAILURE:
		case billingConstants.SUBSCRIBE_AND_CHARGE_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case billingConstants.GET_USER_ACCOUNT_SUMMARY_REQUEST:
			return state;
		case billingConstants.GET_USER_ACCOUNT_SUMMARY_SUCCESS:
			return Object.assign({}, state, {
				summary: {
					// ...state.summary, // maybe we should not reload complete summary???
					billing: action.billing,
					subscription: action.subscription,
					lastPayment: action.lastPayment,
				},
			});
		case billingConstants.GET_USER_ACCOUNT_SUMMARY_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		case billingConstants.GET_USER_ACCOUNT_SUMMARY_PAYMENTS_REQUEST:
			return state;
		case billingConstants.GET_USER_ACCOUNT_SUMMARY_PAYMENTS_SUCCESS:
			return Object.assign({}, state, {
				summary: {
					...state.summary,
					payments: action.payments,
					payments_page: action.payments_page,
					payments_limit: action.payments_limit,
				},
			});
		case billingConstants.GET_USER_ACCOUNT_SUMMARY_PAYMENTS_FAILURE:
			return Object.assign({}, state, {
				error: action.error,
			});

		default:
			return state;
	}
}
