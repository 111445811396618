import React from "react";
import { AppNavBar } from "_elements/NavBarElement";
// import { AlertPanel } from "_components";

export const SideBarLayout = ({ sidebar, content, show_navbar = true, ...props }) => {
    return <>
        {show_navbar && <AppNavBar className="sidebar-layout-navbar" />}
        <div className="d-flex flex-row sidebar-layout">
            <div id="sidebar" className="bg-light" >
                {sidebar}
            </div>
            <main role="main" className="pt-3 px-4 full-width">
                {/* <AlertPanel className="full-width" /> */}
                {content}
            </main>
        </div>
    </>
}