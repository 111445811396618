import React from "react";

import { Nav, NavDropdown } from "_components/Base";
import { LinkContainer } from "react-router-bootstrap";

import { connect } from "react-redux";

import config from "_configs";

export const ServiceList = ({ companyId, ...props }) => {
	const services = config.NAVBAR.getServices(companyId);
	return <NavDropdown
		// className="col-sm-3 col-md-2 mr-0"
		title="Modules"
		id="collasible-nav-dropdown"
		{...props}
	>
		{services.map((service, indx) => (
			<LinkContainer key={indx} to={service.url} activeClassName="">
				<NavDropdown.Item>{service.title}</NavDropdown.Item>
			</LinkContainer>
		))}
	</NavDropdown>
}

const LeftNavBar = (props) => {
	const { loggedIn, company, user } = props;
	if (!loggedIn) {
		return <Nav activeKey="/" className="mr-auto ml-md-5">
			{/* <LinkContainer to={config.clientUrls.HOME}>
				<Nav.Item><Nav.Link active={false} href={config.clientUrls.HOME}>What we offer</Nav.Link></Nav.Item>
			</LinkContainer> */}
			<Nav.Item>
				<Nav.Link active={false} href={config.clientUrls.HOME_FEATURES}>Features</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link active={false} href={config.clientUrls.HOME_PRICING}>Pricing</Nav.Link>
			</Nav.Item>
			<Nav.Item>
				<Nav.Link active={false} href={config.clientUrls.HOME_FOUNDERS}>Who we are</Nav.Link>
			</Nav.Item>
			{/* <Button variant="link" className="nav-link"
				onClick={() => { history.push(config.clientUrls.HOME) }}>
				What we offer
			</Button>
			<Button variant="link" className="nav-link"
				onClick={() => { history.push(config.clientUrls.HOME_PRICING) }}>
				Pricing
			</Button>
			<Button variant="link" className="nav-link"
				onClick={() => { history.push(config.clientUrls.HOME_FOUNDERS) }}>
				Who we are
			</Button> */}
		</Nav>
	}

	let companyId;
	if (company.companyId) {
		companyId = company.companyId;
	} else if (user.default_company) {
		companyId = user.default_company.companyId;
	}

	if (!companyId || !company.system || company.isReconnectionNeeded) {
		return null;
	}

	return (
		<Nav activeKey="/" className="mr-auto ml-md-5">
			<ServiceList companyId={companyId} className="d-none d-md-block" />
		</Nav>
	);
}

function mapState(state) {
	const { loggedIn, user } = state.authentication;
	const { company } = state.bookkeeping;
	return { loggedIn, user, company };
}

const actionCreators = {};

const connectedNavBar = connect(mapState, actionCreators)(LeftNavBar);
export { connectedNavBar as LeftNavBar };
