import React from "react";

export const SigninFrame = ({ children, className, ...props }) => {
    let classNames = ["signin-frame"];
    if (className) {
        classNames.push(className);
    }
    return <div className="h-center">
        <div className={classNames.join(' ')}>
            {children}
        </div>
    </div>
}