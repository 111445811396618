import React from "react";
import { Footer } from "_components/Footer";

export const FooterLayout = ({ children, ...props }) => {
    return <div className="content-footer-container">
        <div className="content">
            {children}
        </div>
        <Footer />
    </div>
}