import React from "react";

export const XH = ({ children, className = "", ...props }) =>
    <h1 className={"text-x-title " + className} {...props} >
        {children}
    </h1 >

export const H0Brand = ({ children, className = "", ...props }) =>
    <h1 className={"text-super-title-brand " + className} {...props} >
        {children}
    </h1 >
export const H0 = ({ children, className = "", ...props }) =>
    <h1 className={"text-super-title " + className} {...props} >
        {children}
    </h1 >

export const H1 = ({ children, className = "", ...props }) =>
    <h1 className={"text-title " + className} {...props} >
        {children}
    </h1 >

export const H2 = ({ children, className = "", ...props }) =>
    <h1 className={"text-heading " + className} {...props} >
        {children}
    </h1 >

export const H3 = ({ children, className = "", ...props }) =>
    <h1 className={"text-subheading " + className} {...props} >
        {children}
    </h1 >
