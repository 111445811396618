import React from "react";
import { Row, Button } from "_components/Base";
import { history } from "_helpers";
import configs from "_configs";

export const Footer = (props) => {
    const date = new Date();
    return <footer className="footer d-flex h-center">
        <Row className="d-flex container-sized full-width justify-content-between flex-row x-padding-1">
            <p className="footer-text">&#169;KaleStack {date.getFullYear()} All rights reserved. Various trademarks held by their respective owners.</p>
            <div className="p-0">
                <Button
                    variant="link"
                    onClick={(e) => { history.push(configs.clientUrls.POLICY) }}
                    className="no-borders footer-button pr-3"
                >
                    Privacy Policy
			    </Button>

                <Button
                    variant="link"
                    onClick={(e) => { history.push(configs.clientUrls.TERMS) }}
                    className="no-borders footer-button p-0"
                >
                    Terms & Conditions
			    </Button>
            </div>
        </Row>
    </footer >
}