import React from "react";
import { H0, H1, H2, H3 } from "_components/Base";

export const PriceCard = ({ plan, isCurrent, currency, buttonComponent, highlighted, popular = false }) => {
    let bulletsBlock = plan.bullets.map((bullet, index) => (
        <li key={index}>
            {index === 0 ? <H3 className='mb-0'>{bullet}</H3> : bullet}
        </li>
    ));

    if (isCurrent) {
        bulletsBlock.push(
            <li key={"current-plan-li"}>
                <b>Current plan</b>
            </li>
        );
    }
    const popularClassName = popular ? "popular" : "";
    const price = plan.priceMulti && plan.priceMulti[currency] ? plan.priceMulti[currency] : plan.price
    return (
        <>
            <div key={plan._id} className={`card mb-4 ${highlighted ? "card-highlighted" : ""} ${popularClassName}`}>
                <div className={`card-header ${popularClassName}`}>
                    {/* <h4 className="my-0 font-weight-normal">{plan.name}</h4> */}
                    <H2 className="d-flex flex-row h-center mb-0">
                        {popular && <img className="mr-2" src="/assets/icons/single-kale-leaf.svg" alt="single-kale-leaf" />}
                        {plan.name}
                    </H2>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-row h-center">
                        <H0 className='mr-2'>${price}</H0>
                        <H1>/ mo</H1>
                    </div>
                    <ul className="list-unstyled mt-4 mb-5 h-center">{bulletsBlock}</ul>
                    {buttonComponent}
                </div>
            </div>
        </>
    );
}