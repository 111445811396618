import React from "react";
import { Route } from "_components";
import config from "./config";
import { getKnowHowRoutes as knowHowPagesRoutes } from "./pages";
import KnowHow from "./KnowHow";

export async function getKnowHowRoutes() {
    const pagesRoutes = await knowHowPagesRoutes();
    pagesRoutes.push(
        < Route
            key="know-how-route-main"
            path={config.index.url}
            component={KnowHow} />
    )
    return pagesRoutes;
}