import { alertConstants } from "_constants";
const initialState = {
	error: {},
	success: {},
	warning: {},
};
export function alert(state = initialState, action) {
	switch (action.type) {
		case alertConstants.CLEAR_ALL:
			return initialState;
		case alertConstants.SUCCESS:
			return Object.assign({}, state, {
				success: {
					type: "alert-success",
					message: action.message,
					toastId: action.id,
				},
			});
		case alertConstants.ERROR:
			return Object.assign({}, state, {
				error: {
					type: "alert-error",
					message: action.message,
					toastId: action.id,
				},
			});
		case alertConstants.PERSISTENT_ERROR:
			return Object.assign({}, state, {
				persistentError: {
					type: "alert-persistent-error",
					message: action.message,
					toastId: action.id,
				},
			});
		case alertConstants.WARNING:
			return Object.assign({}, state, {
				warning: {
					type: "alert-warning",
					// type: "alert-danger",
					message: action.message,
					toastId: action.id,
				},
			});
		case alertConstants.CLEAR_PERSISTENT_ERROR:
			return Object.assign({}, state, {
				persistentError: {},
				warning: {}
			});
		case alertConstants.CLEAR_ERROR:
			return Object.assign({}, state, {
				error: {},
				warning: {}
			});
		case alertConstants.CLEAR_WARNING:
			return Object.assign({}, state, {
				warning: {},
			});
		case alertConstants.CLEAR_SUCCESS:
			return Object.assign({}, state, {
				success: {},
			});
		default:
			return state;
	}
}
