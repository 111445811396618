export const billingConstants = {
	SUBSCRIBE_AND_CHARGE: "SUBSCRIBE_AND_CHARGE",

	SUBSCRIBE_AND_CHARGE_REQUEST: "SUBSCRIBE_AND_CHARGE_REQUEST",
	SUBSCRIBE_AND_CHARGE_SUCCESS: "SUBSCRIBE_AND_CHARGE_SUCCESS",
	SUBSCRIBE_AND_CHARGE_FAILURE: "SUBSCRIBE_AND_CHARGE_FAILURE",

	MODIFY_SUBSCRIPTION: "MODIFY_SUBSCRIPTION",

	MODIFY_SUBSCRIPTION_REQUEST: "MODIFY_SUBSCRIPTION_REQUEST",
	MODIFY_SUBSCRIPTION_SUCCESS: "MODIFY_SUBSCRIPTION_SUCCESS",
	MODIFY_SUBSCRIPTION_FAILURE: "MODIFY_SUBSCRIPTION_FAILURE",

	CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",

	CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
	CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
	CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE",

	GET_USER_ACCOUNT_SUMMARY: "GET_USER_ACCOUNT_SUMMARY",

	GET_USER_ACCOUNT_SUMMARY_REQUEST: "GET_USER_ACCOUNT_SUMMARY_REQUEST",
	GET_USER_ACCOUNT_SUMMARY_SUCCESS: "GET_USER_ACCOUNT_SUMMARY_SUCCESS",
	GET_USER_ACCOUNT_SUMMARY_FAILURE: "GET_USER_ACCOUNT_SUMMARY_FAILURE",

	GET_USER_ACCOUNT_SUMMARY_PAYMENTS: "GET_USER_ACCOUNT_SUMMARY_PAYMENTS",

	GET_USER_ACCOUNT_SUMMARY_PAYMENTS_REQUEST: "GET_USER_ACCOUNT_SUMMARY_PAYMENTS_REQUEST",
	GET_USER_ACCOUNT_SUMMARY_PAYMENTS_SUCCESS: "GET_USER_ACCOUNT_SUMMARY_PAYMENTS_SUCCESS",
	GET_USER_ACCOUNT_SUMMARY_PAYMENTS_FAILURE: "GET_USER_ACCOUNT_SUMMARY_PAYMENTS_FAILURE",

};
