import { bookkeepingConstants, PEBookkeepingConstants } from "_constants";

function flagConstantGenerator(flagConstant) {
	return {
		loading: `loading_${flagConstant}`,
		loaded: `loaded_${flagConstant}`,
	};
}

const qboConnectFlags = flagConstantGenerator(bookkeepingConstants.QBO_CONNECT);
const qboCallbackFlags = flagConstantGenerator(
	bookkeepingConstants.QBO_CALLBACK
);
const xeroConnectFlags = flagConstantGenerator(
	bookkeepingConstants.XERO_CONNECT
);
const xeroCallbackFlags = flagConstantGenerator(
	bookkeepingConstants.XERO_CALLBACK
);
const getCompaniesFlags = flagConstantGenerator(
	bookkeepingConstants.GET_COPMANIES_LIST
);
const getCompanyDetailsFlags = flagConstantGenerator(
	bookkeepingConstants.GET_COMPANY_DETAILS
);
const createCompanyFlags = flagConstantGenerator(
	bookkeepingConstants.CREATE_COMPANY
);
const deleteCompanyFlags = flagConstantGenerator(
	bookkeepingConstants.DELETE_COMPANY
);
const getDefaultOrCreateCompanyFlags = flagConstantGenerator(
	bookkeepingConstants.GET_DEFAULT_CREATE_COMPANY
);
const getAccountListFlags = flagConstantGenerator(
	bookkeepingConstants.GET_ACCOUNT_LIST
);
const setPEAccountFlags = flagConstantGenerator(
	PEBookkeepingConstants.SET_PE_ACCOUNT
);
const addPEFlags = flagConstantGenerator(
	PEBookkeepingConstants.ADD_PE
);
const deletePEScheduleFlags = flagConstantGenerator(
	PEBookkeepingConstants.DELETE_PE_SCHEDULE
);
const getPEListFlags = flagConstantGenerator(PEBookkeepingConstants.GET_PE_LIST);
const getPEScheduleFlags = flagConstantGenerator(
	PEBookkeepingConstants.GET_PE_SCHEDULE
);
const getPEDataToBeRecognizedFlags = flagConstantGenerator(
	PEBookkeepingConstants.GET_PE_DATA_TO_BE_RECOGNIZED
);
const confirmRecognizePEDataFlags = flagConstantGenerator(
	PEBookkeepingConstants.CONFIRM_RECOGNIZE_PE_DATA
);
const rollbackRecognizePEDataFlags = flagConstantGenerator(
	PEBookkeepingConstants.ROLLBACK_RECOGNIZE_PE_DATA
);
const exportPEListFlags = flagConstantGenerator(
	PEBookkeepingConstants.EXPORT_PE_LIST
);

const makeCompanyDefaultFlags = flagConstantGenerator(
	bookkeepingConstants.MAKE_COMPANY_DEFAULT
);

export const bookkeepingFlagConstants = {
	qboConnectFlags,
	qboCallbackFlags,
	xeroConnectFlags,
	xeroCallbackFlags,

	getCompaniesFlags,
	getCompanyDetailsFlags,
	makeCompanyDefaultFlags,

	createCompanyFlags,
	deleteCompanyFlags,

	getDefaultOrCreateCompanyFlags,

	getAccountListFlags,
	setPEAccountFlags,
	addPEFlags,

	deletePEScheduleFlags,

	getPEListFlags,
	getPEScheduleFlags,

	getPEDataToBeRecognizedFlags,
	confirmRecognizePEDataFlags,
	rollbackRecognizePEDataFlags,

	exportPEListFlags,
};
