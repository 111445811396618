import React from "react";

import { Navbar, H1 } from "_components/Base";
import { LinkContainer } from "react-router-bootstrap";

import { connect } from "react-redux";

import { RightNavBar } from "./RightNavBar";
import { LeftNavBar } from "./LeftNavBar";

import { Brand, SettingsIcon } from "_components"
import configs from "_configs";

const AppNavBar = ({ className, ...props }) => {
	return (
		<Navbar
			// className="pl-auto pt-0 pb-0 pr-0 shadow"
			className={`shadow px-3`} //d-flex h-center
			collapseOnSelect
			// fixed="top"
			// expand="sm"
			expand="md"
			// variant="dark"
			// bg="dark"
			variant="light"
			bg="light"
		>
			{/* <div className={`d-flex justify-content-between x-padding-1 full-width container-sized ${className}`}> */}
			<div className={`container-md container-sized px-0`}  >
				{/* <Container> */}
				<LinkContainer exact to={configs.clientUrls.HOME}>
					<Navbar.Brand
						href={configs.clientUrls.HOME}
					>
						<H1><Brand /></H1>
					</Navbar.Brand>
				</LinkContainer>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" /*className="no-borders no-outline"*/ >
					<SettingsIcon style={{ fontSize: "20px" }} />
				</Navbar.Toggle>
				<Navbar.Collapse id="responsive-navbar-nav" className="">
					<LeftNavBar />
					<RightNavBar />
				</Navbar.Collapse>
				{/* </Container> */}
			</div>
		</Navbar >
	);
}

function mapState(state) {
	const { user, loggedIn } = state.authentication;
	return { user, loggedIn };
}

const actionCreators = {};

const connectedNavBar = connect(mapState, actionCreators)(AppNavBar);
export { connectedNavBar as AppNavBar };
