import React from "react";

import { Form, Col, Button } from "_components/Base";

export const InlineForm = ({ labelTitle, inputValue, inputOnChange, inputPlaceholder, btnTitle, btnOnSubmit, ...props }) => {
    return <Form>
        <Form.Row className="align-items-center">
            {labelTitle && <Col md={2}>{labelTitle}</Col>}
            <Col md={9}>
                <Form.Control
                    className="mb-2"
                    id="inlineFormInput"
                    type="text"
                    placeholder={inputPlaceholder}
                    value={inputValue}
                    onChange={inputOnChange}
                />
            </Col>
            <Col md={1}>
                <Button className="mb-2" onClick={btnOnSubmit}>
                    {btnTitle}
                </Button>
            </Col>
        </Form.Row>
    </Form>
}