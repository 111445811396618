export const webfaceStateConstants = {
	UPDATE_WEBFACE_TEMPLATE: "UPDATE_WEBFACE_TEMPLATE",
	CHANGE_PE_PANNEL: "CHANGE_PE_PANNEL",
	CHANGE_AL_PANNEL: "CHANGE_AL_PANNEL",
	CHANGE_MODAL_WINDOW_STATUS: "CHANGE_MODAL_WINDOW_STATUS",

	// REDUCER CONSTANTS
	PREPAID_EXPENSE_PANELS: {
		SELECT_PE_ACCOUNT_PANEL: "SELECT_PE_ACCOUNT_PANEL",
		LIST_PE_PANEL: "LIST_PE_PANEL",
		ADD_PE_PANEL: "ADD_PE_PANEL",
		RECOGNIZE_PE_PANEL: "RECOGNIZE_PE_PANEL",
		ROLLBACK_RECOGNIZE_PE_PANEL: "ROLLBACK_RECOGNIZE_PE_PANEL"
	},
	ACCRUED_LIABILITY_PANELS: {
		SELECT_AL_ACCOUNT_PANEL: "SELECT_AL_ACCOUNT_PANEL",
		LIST_AL_PANEL: "LIST_AL_PANEL",
		ADD_AL_PANEL: "ADD_AL_PANEL",
		RECOGNIZE_AL_PANEL: "RECOGNIZE_AL_PANEL",
		ROLLBACK_RECOGNIZE_AL_PANEL: "ROLLBACK_RECOGNIZE_AL_PANEL"

	}
};
