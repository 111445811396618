import config from "_configs";
import { authHeader, handleResponse } from "_helpers";

const POST_JSON_HEADER = { "Content-Type": "application/json" };


export const adminService = {
    getUsers,
    lockUsers,
    unlockUsers,
    convertToDevAccount,
    giveDiscount,
    sendEmail,
    editPlan,
    listPlans,
};


function getUsers(filter = "all") {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: 'include',
    };
    const re = /([a-z\d_.]+)=\s*("[^"]+"|[^\s]+)/gi,
        reNoSpace = "$1=$2";
    let suffix = "";
    const matches = filter.match(re) || [];
    if (filter.toLowerCase() !== "all" && matches.length > 0) {
        suffix = encodeURI("?" + matches.map(match => match.replace(re, reNoSpace)).join("&"));
    }
    return fetch(
        `${config.apiUrl}/admin/get_users${suffix}`,
        requestOptions
    ).then(handleResponse);
}

function lockUsers(userIds) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({
            ids: userIds,
        }),
    }

    return fetch(
        `${config.apiUrl}/admin/lock_users`,
        requestOptions
    ).then(handleResponse);
}

function unlockUsers(userIds) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({
            ids: userIds,
        }),
    }

    return fetch(
        `${config.apiUrl}/admin/unlock_users`,
        requestOptions
    ).then(handleResponse);
}

function convertToDevAccount(userIds) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({
            ids: userIds,
        }),
    }

    return fetch(
        `${config.apiUrl}/admin/convert_to_dev_account`,
        requestOptions
    ).then(handleResponse);
}


function giveDiscount(userIds, discount) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({
            ids: userIds,
            discount
        }),
    }

    return fetch(
        `${config.apiUrl}/admin/give_discount`,
        requestOptions
    ).then(handleResponse);
}

function sendEmail(userIds, emailBody, emailSubject) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({
            ids: userIds,
            emailBody,
            emailSubject
        }),
    }

    return fetch(
        `${config.apiUrl}/admin/send_email`,
        requestOptions
    ).then(handleResponse);
}

function editPlan(planId, data) {
    const requestOptions = {
        method: "PATCH",
        headers: { ...authHeader(), ...POST_JSON_HEADER },
        credentials: 'include',
        body: JSON.stringify({ data }),
    }

    return fetch(
        `${config.apiUrl}/admin/plans/${planId}`,
        requestOptions
    ).then(handleResponse);
}

function listPlans() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
        credentials: 'include',
    }

    return fetch(
        `${config.apiUrl}/admin/plans`,
        requestOptions
    ).then(handleResponse);
}
