import { webfaceStateConstants, userConstants } from "_constants";

const initialState = {
	activePEPanel: webfaceStateConstants.PREPAID_EXPENSE_PANELS.LIST_PE_PANEL,
	activeALPanel: webfaceStateConstants.ACCRUED_LIABILITY_PANELS.LIST_AL_PANEL,
	modalWindow: { open: false, allowDelete: false },
};

export function webfaceState(state = initialState, action) {
	switch (action.type) {
		case userConstants.CLEAR_ALL:
			return initialState;
		case webfaceStateConstants.CHANGE_PE_PANNEL:
			return Object.assign({}, state, {
				activePEPanel: action.newPanel,
			});
		case webfaceStateConstants.CHANGE_AL_PANNEL:
			return Object.assign({}, state, {
				activeALPanel: action.newPanel
			});
		case webfaceStateConstants.CHANGE_MODAL_WINDOW_STATUS:
			return Object.assign({}, state, {
				modalWindow: { open: !state.modalWindow.open },
			});
		default:
			return state;
	}
}
