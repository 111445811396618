import React from "react";
import { Button } from "_components/Base";

const ButtonQBOConnect = ({ onClick, ...rest }) => (
	<Button isLink={true} onClick={onClick}>
		<img
			style={{ border: "1px solid white", borderRadius: "5px" }}
			src="/assets/img/btn_qbo/C2QB_green_btn_tall_default.svg"
			// height="48"
			alt="Connect to QuickBooks Online"
			className="integration-button"
		/>
	</Button>
);

const ButtonQBOSignIn = ({ onClick, ...rest }) => (
	<Button variant="outline-success" onClick={onClick} {...rest}>
		<div className="d-flex flex-row justify-content-between">
			<span></span>
			<span>Sign in with Intuit</span>
			<img
				style={{ height: "20px" }}
				src="/assets/img/btn_qbo/C2QB_white_btn_sm_default.png"
				alt="Sign in with Intuit"
				className="integration-button"
			/>
		</div>
	</Button>
);

const ButtonQBODisconnect = ({ onClick, title = "Disconnect from QuickBooks Online*", ...rest }) => (
	<Button className="disconnect-integration-button"
		variant="outline-secondary" onClick={onClick}
	>
		{title}
	</Button>
);

export { ButtonQBOConnect, ButtonQBODisconnect, ButtonQBOSignIn };
