import { userConstants } from "_constants";

function flagConstantGenerator(flagConstant) {
	return {
		loading: `loading_${flagConstant}`,
		loaded: `loaded_${flagConstant}`
	};
}

const loginFlags = flagConstantGenerator(userConstants.LOGIN);
const registerFlags = flagConstantGenerator(userConstants.REGISTER);
const getProfileFlags = flagConstantGenerator(userConstants.USERS_GET_PROFILE);
const deleteFlags = flagConstantGenerator(userConstants.DELETE);
const changeSubscriptionFlags = flagConstantGenerator(
	userConstants.CHANGE_SUBSCRIPTION
);
const verify2FASetupFlags = flagConstantGenerator(
	userConstants.VERIFY_2FA_SETUP
);

export const userFlagConstants = {
	loginFlags,
	registerFlags,
	getProfileFlags,
	deleteFlags,
	changeSubscriptionFlags,
	verify2FASetupFlags
};
