import React from "react";
import { Form as FormBS } from "react-bootstrap";
import DatePicker from "react-datepicker";

const FormControl = ({ children, className, type, custom = true, sized = false, ...props }) => {
    let classNames = [];

    if (custom) {
        classNames.push("form-control");
    }
    if (sized) {
        classNames.push("input-sized");
    }
    classNames.push(className);
    return <>
        <FormBS.Control
            className={classNames.join(" ")}
            type={type}
            {...props}
        >
            {children}
        </FormBS.Control>
    </>
}

const DateControl = ({ className, ...props }) => {
    return <DatePicker
        className={`form-control ${className}`}
        {...props}
        popperModifiers={{
            flip: {
                behavior: ["bottom"], // don't allow it to flip to be above
            },
            preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
            },
        }}
    />
}

// list all the statics
FormControl.Feedback = FormBS.Control.Feedback;


const Form = {
    ...FormBS,
    Control: FormControl,
    DateControl: DateControl
};


export { Form }