const config = {
    index: { url: '/knowhow', tags: ['main', 'page'] },
    pages: {
        KnowHow1: {
            url: '/knowhow/page1',
            order: 1,
            title: "Example KnowHow page 1",
            description: "Should we include description? Maybe it's a good idea for a short abstract here",
            tags: ['knowhow', 'prepaid expenses'],
            // img could be empty for a random SVG 
            img: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Emoji_u263a.svg"
        },
        KnowHow2: {
            url: '/knowhow/page2',
            order: 2,
            title: "Example KnowHow page 2 (default everything?)",
            // description could be missing or undefined
            description: undefined,
            tags: ['knowhow', 'prepaid expenses'],
            // img could be empty for a random SVG 
        },
        KnowHow3: {
            "order": 3,
            "title": "Template title",
            "description": "Template description",
            "tags": [
                "knowhow",
                "template-tag"
            ],
            "img": "https://upload.wikimedia.org/wikipedia/commons/e/ef/Emoji_u263a.svg",
            "url": "/knowhow/page3"
        },
        // {TAG:END OF PAGES} @DO-NOT-DELETE
    }
};

export default config;
