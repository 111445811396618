import config from "_configs";
import { authHeader, handleResponseCompanyFallback } from "_helpers";

import { saveAs } from "file-saver";
import moment from "moment";

export const PEBookkeepingService = {
	setPEAccount,
	addPE,

	deletePESchedule,

	getPEList,
	getPESchedule,

	getPEDataToBeRecognized,
	confirmRecognizePEData,
	rollbackRecognizePEData,

	exportPEList,
};

const POST_JSON_HEADER = { "Content-Type": "application/json" };

function exportPEList(company_id, filterObj) {
	const keywordMapper = {
		start_from: "start_from",
		start_to: "start_to",
		created_from: "created_from",
		created_to: "created_to",
		amount_from: "amount_from",
		amount_to: "amount_to",
	};
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	let suffixArr = [];
	let suffix = "";
	if (filterObj) {
		Object.keys(filterObj)
			.filter((keyword) => !!keywordMapper[keyword])
			.forEach((keyword) => suffixArr.push(filterObj[keyword]));
		if (suffixArr.length > 0) {
			suffix = `?${suffixArr.join("&")}`;
		}
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/export${suffix}`,
		requestOptions
	)
		.then((response) => response.blob())
		.then((blob) => {
			saveAs(blob, `mf_pe_${moment().format("YYYY-MM-DD")}.csv`);
			return null;
		});
}

function getPEList(company_id, filterObj) {
	const keywordMapper = {
		start_from: "start_from",
		start_to: "start_to",
		created_from: "created_from",
		created_to: "created_to",
		amount_from: "amount_from",
		amount_to: "amount_to",
	};
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	let suffixArr = [];
	let suffix = "";
	if (filterObj) {
		Object.keys(filterObj)
			.filter((keyword) => !!keywordMapper[keyword])
			.forEach((keyword) => suffixArr.push(filterObj[keyword]));
		if (suffixArr.length > 0) {
			suffix = `?${suffixArr.join("&")}`;
		}
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/list${suffix}`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function setPEAccount(company_id, account_id) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ account_id: account_id }),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/account`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function addPE(
	company_id,
	prepaid_expense_data,
	buildOwnSchedule,
	ownSchedule
) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			...prepaid_expense_data,
			ownSchedule,
			buildOwnSchedule,
		}),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/new`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function deletePESchedule(company_id, journal_id) {
	const requestOptions = {
		method: "DELETE",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/${journal_id}/delete`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function getPESchedule(company_id, journal_id) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/${journal_id}/schedule`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function getPEDataToBeRecognized(company_id, lastDateToRecognize) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	let suffix = "";
	if (lastDateToRecognize) {
		suffix = `/${lastDateToRecognize}`;
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/to_recognize${suffix}`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function confirmRecognizePEData(company_id, startDate, endDate, totalAmount) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			start: startDate,
			end: endDate,
			total: totalAmount,
		}),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/recognize`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function rollbackRecognizePEData(company_id, rollbackDate) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ rollbackDate: rollbackDate }),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/prepaid_expenses/rollback`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}
