import React from "react";

export const GrayBackDrop = ({ children, minHeight, className, style, ...props }) => {
    let newStyle = {};
    if (minHeight) {
        newStyle["minHeight"] = minHeight;
    }
    let classNames = ["gray-backdrop"];
    if (className) {
        classNames.push(className)
    }
    return <div className={classNames.join(" ")} style={{ ...newStyle, ...style }} {...props}>
        {children}
    </div >
}