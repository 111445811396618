import { loadingAPIConstants } from "_constants";

export function loadingAPI(state = {}, action) {
  switch (action.type) {
    case loadingAPIConstants.START_LOADING_API:
      return {
        loading: action.loading
      };
    case loadingAPIConstants.STOP_LOADING_API:
      return {};
    default:
      return state;
  }
}
