import React from "react";
import { AppNavBar } from "_elements/NavBarElement";
// import { AlertPanel } from "_components";
import { Container } from "_components/Base";

export const DefaultLayout = ({ content, show_navbar = true, container_wrapped = true, ...props }) => {
    let internalContent = <>
        {/* <AlertPanel className="container-md container-sized full-width mt-2" /> */}
        {content}</>
    if (container_wrapped) {
        internalContent = <Container className="container-sized px-0 pt-3">{internalContent}</Container>
    }
    return <>
        {show_navbar && <AppNavBar />}
        {internalContent}
    </>
}