export const PEBookkeepingConstants = {
	SET_PE_ACCOUNT: "SET_PE_ACCOUNT",

	SET_PE_ACCOUNT_REQUEST: "SET_PE_ACCOUNT_REQUEST",
	SET_PE_ACCOUNT_SUCCESS: "SET_PE_ACCOUNT_SUCCESS",
	SET_PE_ACCOUNT_FAILURE: "SET_PE_ACCOUNT_FAILURE",

	ADD_PE: "ADD_PE",

	ADD_PE_REQUEST: "ADD_PE_REQUEST",
	ADD_PE_SUCCESS: "ADD_PE_SUCCESS",
	ADD_PE_FAILURE: "ADD_PE_FAILURE",

	DELETE_PE_SCHEDULE: "DELETE_PE_SCHEDULE",

	DELETE_PE_SCHEDULE_REQUEST: "DELETE_PE_SCHEDULE_REQUEST",
	DELETE_PE_SCHEDULE_SUCCESS: "DELETE_PE_SCHEDULE_SUCCESS",
	DELETE_PE_SCHEDULE_FAILURE: "DELETE_PE_SCHEDULE_FAILURE",

	GET_PE_LIST: "GET_PE_LIST",

	GET_PE_LIST_REQUEST: "GET_PE_LIST_REQUEST",
	GET_PE_LIST_SUCCESS: "GET_PE_LIST_SUCCESS",
	GET_PE_LIST_FAILURE: "GET_PE_LIST_FAILURE",

	EXPORT_PE_LIST: "EXPORT_PE_LIST",

	EXPORT_PE_LIST_REQUEST: "EXPORT_PE_LIST_REQUEST",
	EXPORT_PE_LIST_SUCCESS: "EXPORT_PE_LIST_SUCCESS",
	EXPORT_PE_LIST_FAILURE: "EXPORT_PE_LIST_FAILURE",

	GET_PE_SCHEDULE: "GET_PE_SCHEDULE",

	GET_PE_SCHEDULE_REQUEST: "GET_PE_SCHEDULE_REQUEST",
	GET_PE_SCHEDULE_SUCCESS: "GET_PE_SCHEDULE_SUCCESS",
	GET_PE_SCHEDULE_FAILURE: "GET_PE_SCHEDULE_FAILURE",

	GET_PE_DATA_TO_BE_RECOGNIZED: "GET_PE_DATA_TO_BE_RECOGNIZED",

	GET_PE_DATA_TO_BE_RECOGNIZED_REQUEST: "GET_PE_DATA_TO_BE_RECOGNIZED_REQUEST",
	GET_PE_DATA_TO_BE_RECOGNIZED_SUCCESS: "GET_PE_DATA_TO_BE_RECOGNIZED_SUCCESS",
	GET_PE_DATA_TO_BE_RECOGNIZED_FAILURE: "GET_PE_DATA_TO_BE_RECOGNIZED_FAILURE",

	CONFIRM_RECOGNIZE_PE_DATA: "CONFIRM_RECOGNIZE_PE_DATA",

	CONFIRM_RECOGNIZE_PE_DATA_REQUEST: "CONFIRM_RECOGNIZE_PE_DATA_REQUEST",
	CONFIRM_RECOGNIZE_PE_DATA_SUCCESS: "CONFIRM_RECOGNIZE_PE_DATA_SUCCESS",
	CONFIRM_RECOGNIZE_PE_DATA_FAILURE: "CONFIRM_RECOGNIZE_PE_DATA_FAILURE",

	ROLLBACK_RECOGNIZE_PE_DATA: "ROLLBACK_RECOGNIZE_PE_DATA",

	ROLLBACK_RECOGNIZE_PE_DATA_REQUEST: "ROLLBACK_RECOGNIZE_PE_DATA_REQUEST",
	ROLLBACK_RECOGNIZE_PE_DATA_SUCCESS: "ROLLBACK_RECOGNIZE_PE_DATA_SUCCESS",
	ROLLBACK_RECOGNIZE_PE_DATA_FAILURE: "ROLLBACK_RECOGNIZE_PE_DATA_FAILURE",

	DELETE_PES_INLINE: "DELETE_PES_INLINE",
};
