import config from "_configs";
import { authHeader, handleResponseCompanyFallback } from "_helpers";

import { saveAs } from "file-saver";
import moment from "moment";

export const ALBookkeepingService = {
	setALAccount,
	addAL,

	deleteALSchedule,

	getALList,
	getALSchedule,

	getALDataToBeRecognized,
	confirmRecognizeALData,
	rollbackRecognizeALData,

	exportALList,
};

const POST_JSON_HEADER = { "Content-Type": "application/json" };

function exportALList(company_id, filterObj) {
	const keywordMapper = {
		start_from: "start_from",
		start_to: "start_to",
		created_from: "created_from",
		created_to: "created_to",
		amount_from: "amount_from",
		amount_to: "amount_to",
	};
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	let suffixArr = [];
	let suffix = "";
	if (filterObj) {
		Object.keys(filterObj)
			.filter((keyword) => !!keywordMapper[keyword])
			.forEach((keyword) => suffixArr.push(filterObj[keyword]));
		if (suffixArr.length > 0) {
			suffix = `?${suffixArr.join("&")}`;
		}
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/export${suffix}`,
		requestOptions
	)
		.then((response) => response.blob())
		.then((blob) => {
			saveAs(blob, `mf_al_${moment().format("YYYY-MM-DD")}.csv`);
			return null;
		});
}

function getALList(company_id, filterObj) {
	const keywordMapper = {
		start_from: "start_from",
		start_to: "start_to",
		created_from: "created_from",
		created_to: "created_to",
		amount_from: "amount_from",
		amount_to: "amount_to",
	};
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};

	let suffixArr = [];
	let suffix = "";
	if (filterObj) {
		Object.keys(filterObj)
			.filter((keyword) => !!keywordMapper[keyword])
			.forEach((keyword) => suffixArr.push(filterObj[keyword]));
		if (suffixArr.length > 0) {
			suffix = `?${suffixArr.join("&")}`;
		}
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/list${suffix}`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function setALAccount(company_id, account_id) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ account_id: account_id }),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/account`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function addAL(
	company_id,
	accrued_liability_data,
	buildOwnSchedule,
	ownSchedule
) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			...accrued_liability_data,
			ownSchedule,
			buildOwnSchedule,
		}),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/new`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function deleteALSchedule(company_id, journal_id) {
	const requestOptions = {
		method: "DELETE",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/${journal_id}/delete`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function getALSchedule(company_id, journal_id) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/${journal_id}/schedule`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function getALDataToBeRecognized(company_id, lastDateToRecognize) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	let suffix = "";
	if (lastDateToRecognize) {
		suffix = `/${lastDateToRecognize}`;
	}
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/to_recognize${suffix}`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function confirmRecognizeALData(company_id, startDate, endDate, totalAmount) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({
			start: startDate,
			end: endDate,
			total: totalAmount,
		}),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/recognize`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}

function rollbackRecognizeALData(company_id, rollbackDate) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ rollbackDate: rollbackDate }),
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accrued_liabilities/rollback`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}
