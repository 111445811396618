import { loadingAPIConstants } from "_constants";

export const loadingAPIActions = {
  startLoadingAPI,
  stopLoadingAPI
};

function startLoadingAPI(messageObj) {
  return { type: loadingAPIConstants.START_LOADING_API, loading: true };
}

function stopLoadingAPI() {
  return { type: loadingAPIConstants.STOP_LOADING_API };
}
