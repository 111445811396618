/*TO BE DEPRECATED*/
import React from "react";
import { connect } from "react-redux";

import { Alert } from "_components/Base";

const AlertPanel = ({ alert, ...props }) => {
    return <>
        {alert.success && alert.success.message && (
            <Alert timer={6} variant={alert.success.type} {...props}>
                {alert.success.message}
            </Alert>
        )}
        {alert.persistentError && alert.persistentError.message && (
            <Alert variant={alert.persistentError.type} {...props}>
                {alert.persistentError.message}
            </Alert>
        )}
        {alert.error && alert.error.message && (
            <Alert variant={alert.error.type} {...props}>
                {alert.error.message}
            </Alert>
        )}
        {alert.warning && alert.warning.message && (
            <Alert timer={6} variant={alert.warning.type} {...props}>
                {alert.warning.message}
            </Alert>
        )}

    </>
}


function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
};

const connectedAlertPanel = connect(
    mapState,
    actionCreators
)(AlertPanel);
export { connectedAlertPanel as AlertPanel };
