import React, { useEffect, Suspense, useState } from "react";
import { Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "_helpers";
import { alertActions, userActions } from "_actions";

import { FreezeScreenLoader, PrivateRoute, Route, CacheBuster, IddleTimer, SemiPrivateRoute } from "_components";

import { getKnowHowRoutes } from "_knowHowPages";

import config from "_configs";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "bootstrap/dist/css/bootstrap.min.css";
// import "./dashboard.bootstrap.css";
import "./custom.css";

// airbnb dates
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";

// standard react datepicker dates
// import "react-datepicker/dist/react-datepicker.css";

import "_css/dashboard.css";
import "_css/footer.css";
import "_css/ks_styles.css";
import "_css/spacing.css";
import "_css/flex_rules.css";
import "_css/cards.css";
import "_css/sidebar.css";
import "_css/signin.css";

import ReactGA from 'react-ga4';


const FooterLayout = React.lazy(() => import("_components/Layouts").then(mdl => ({ default: mdl.FooterLayout })));
const HomePage = React.lazy(() => import("_pages/HomePage").then(mdl => ({ default: mdl.HomePage })));
const HelpDesk = React.lazy(() => import("_pages/HelpDesk").then(mdl => ({ default: mdl.HelpDesk })));
const PrepaidExpensesPage = React.lazy(() => import("_pages/modules/PrepaidExpensesPage").then(mdl => ({ default: mdl.PrepaidExpensesPage })));
const AccruedLiabilitiesPage = React.lazy(() => import("_pages/modules/AccruedLiabilitiesPage").then(mdl => ({ default: mdl.AccruedLiabilitiesPage })));

const RegisterPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.RegisterPage })));
const ForgotPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.ForgotPage })));
const LoginPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.LoginPage })));
const ResetPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.ResetPage })));
const VerifyEmailPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.VerifyEmailPage })));
const ConnectPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.ConnectPage })));

const QBOCallbackPage = React.lazy(() => import("_pages/QBO/CallbackPage").then(mdl => ({ default: mdl.CallbackPage })));
const XeroCallbackPage = React.lazy(() => import("_pages/Xero/CallbackPage").then(mdl => ({ default: mdl.CallbackPage })));
const XeroSignInCallbackPage = React.lazy(() => import("_pages/Xero/CallbackPage").then(mdl => ({ default: mdl.SignInCallbackPage })));

const QBOLearnMorePage = React.lazy(() => import("_pages/QBO/LearnMorePage").then(mdl => ({ default: mdl.QBOLearnMorePage })));
const QBODisconnectedPage = React.lazy(() => import("_pages/QBO/QBODisconnectedPage").then(mdl => ({ default: mdl.QBODisconnectedPage })));

const NotFoundPage = React.lazy(() => import("_pages/NotFoundPage").then(mdl => ({ default: mdl.NotFoundPage })));
const NetworkProblemPage = React.lazy(() => import("_pages/NotFoundPage").then(mdl => ({ default: mdl.NetworkProblemPage })));

const PolicyPage = React.lazy(() => import("_pages/PolicyPage").then(mdl => ({ default: mdl.PolicyPage })));
const TermsPage = React.lazy(() => import("_pages/PolicyPage").then(mdl => ({ default: mdl.TermsPage })));

const SubscriptionPage = React.lazy(() => import("_pages/SubscriptionPage").then(mdl => ({ default: mdl.SubscriptionPage })));
const GuideTestPage = React.lazy(() => import("_pages/GuideTestPage").then(mdl => ({ default: mdl.GuideTestPage })));
const AdminHomePage = React.lazy(() => import("_pages/admin/AdminHomePage").then(mdl => ({ default: mdl.AdminHomePage })));

const CompanyPage = React.lazy(() => import("_pages/CompanyPage").then(mdl => ({ default: mdl.CompanyPage })));
const CompanyRedirectPage = React.lazy(() => import("_pages/CompanyPage").then(mdl => ({ default: mdl.CompanyRedirectPage })));
const CompanyHelpPage = React.lazy(() => import("_pages/CompanyPage").then(mdl => ({ default: mdl.CompanyHelpPage })));
const CreateNewCompanyPage = React.lazy(() => import("_pages/CompanyPage").then(mdl => ({ default: mdl.CreateNewCompanyPage })));
const ListCompaniesPage = React.lazy(() => import("_pages/CompanyPage").then(mdl => ({ default: mdl.ListCompaniesPage })));

const CompleteRegistrationPage = React.lazy(() => import("_pages/BillingPage").then(mdl => ({ default: mdl.CompleteRegistrationPage })));
const CompleteSignupPage = React.lazy(() => import("_pages/AuthPages").then(mdl => ({ default: mdl.CompleteSignupPage })));
const BillingSummaryPage = React.lazy(() => import("_pages/BillingPage").then(mdl => ({ default: mdl.BillingSummaryPage })));

const PESideNavBar = React.lazy(() => import("_elements/sidebars").then(mdl => ({ default: mdl.PESideNavBar })));
const ALSideNavBar = React.lazy(() => import("_elements/sidebars").then(mdl => ({ default: mdl.ALSideNavBar })));


const Content = (props) => {
	const [knowHowRoutes, setKnowHowRoutes] = useState([]);
	useEffect(() => {
		async function fetchData() {
			setKnowHowRoutes(await getKnowHowRoutes());
		}
		fetchData();
	}, [])
	return <Switch>
		<Route exact path={config.clientUrls.HOME} component={HomePage} variant={{ default_unwrapped: true }} />
		{knowHowRoutes}
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.MOKAFILE__COMPANY_HOME()}
			component={CompanyRedirectPage}
		/>
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.MOKAFILE__COMPANY__HELP}
			component={CompanyHelpPage}
		/>

		<PrivateRoute
			exact
			allowIncomplete
			path={config.clientUrls.COMPLETE_SIGNUP}
			component={CompleteSignupPage}
		/>

		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.MOKAFILE__LIST_COMPANIES}
			component={ListCompaniesPage}
		/>
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.MOKAFILE__NEW_COMPANY}
			component={CreateNewCompanyPage}
		/>
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.MOKAFILE__COMPANY_HOME(":company_id")}
			component={CompanyPage}
		/>
		<Route path={config.clientUrls.LOGIN} allowIncomplete component={LoginPage} variant={{ signin: true }} />

		<Route path={config.clientUrls.CONNECT} component={ConnectPage} variant={{ signin: true }} />

		<PrivateRoute path={config.clientUrls.HELP} component={HelpDesk} />
		{config.REGISTRATION_OFF ? (
			<Route path={config.clientUrls.REGISTER} component={LoginPage} variant={{ signin: true }} />
		) : (
			<Route path={config.clientUrls.REGISTER} component={RegisterPage} variant={{ signin: true }} allowIncomplete />
		)}

		<Route path={config.clientUrls.FORGOT} component={ForgotPage} />
		<Route path={config.clientUrls.RESET} component={ResetPage} />
		<Route
			path={config.clientUrls.VERIFY_EMAIL}
			component={VerifyEmailPage}
		/>
		{/* QBO Callback can be for adding separate company or for signing in with QBO */}
		<SemiPrivateRoute rule={{ ifLoggedIn: true, and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.QBO_CALLBACK}
			component={QBOCallbackPage}
		/>

		<Route exact path={config.clientUrls.QBO_LEARN_MORE} component={QBOLearnMorePage} variant={{ default_unwrapped: true }} />
		<Route exact path={config.clientUrls.QBO_DISCONNECTED} component={QBODisconnectedPage} variant={{ default_unwrapped: true }} />

		{/* XERO Callback can be for adding separate company or for signing in with XERO */}
		<SemiPrivateRoute rule={{ ifLoggedIn: true, and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.XERO_CALLBACK}
			component={XeroCallbackPage}
		/>
		<SemiPrivateRoute rule={{ ifLoggedIn: true, and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.XERO__SIGN_IN_CALLBACK}
			component={XeroSignInCallbackPage}
		/>
		<Route
			path={config.clientUrls.SUBSCRIPTION}
			component={SubscriptionPage}
		/>
		<PrivateRoute
			// @TBD Billing summary might be available for all logged in
			//rule={{or: ["isBillable","isTrial"]}} ruleRedirect={config.clientUrls.LOGIN}
			path={config.clientUrls.USER_ACCOUNT_SUMMARY}
			component={BillingSummaryPage}
		/>
		<PrivateRoute exact
			rule={{ and: ["isTrial", "~isBillable"] }}
			ruleRedirect={config.clientUrls.USER_ACCOUNT_SUMMARY}
			path={config.clientUrls.COMPLETE_REGISTRATION}
			component={CompleteRegistrationPage}
		/>
		<Route path={config.clientUrls.POLICY} component={PolicyPage} />
		<Route path={config.clientUrls.EULA} component={TermsPage} />
		<Route path={config.clientUrls.TERMS} component={TermsPage} />
		<Route path={config.clientUrls.GUIDE_TEST} component={GuideTestPage} />
		<Route
			path={config.clientUrls.MOKAFILE__NETWORK__PROBLEM}
			component={NetworkProblemPage}
		/>

		{/* WITH SIDEBAR */}
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.PREPAID_EXPENSES_MODULE(":company_id")}
			component={PrepaidExpensesPage}
			variant={{ sidebar: <PESideNavBar /> }}
		/>
		<PrivateRoute
			exact rule={{ and: ["hasSubscription", "isPaid"] }}
			path={config.clientUrls.ACCRUED_LIABILITIES_MODULE(":company_id")}
			component={AccruedLiabilitiesPage}
			variant={{ sidebar: <ALSideNavBar /> }}
		/>

		{/* ADMIN ROUTES BEGIN*/}
		<PrivateRoute
			rule="isAdmin"
			path={config.adminUrls.HOME}
			component={AdminHomePage}
		/>
		{/* ADMIN ROUTES END */}
		<Route component={NotFoundPage} />

	</Switch>
}

const App = ({ loadingAPI, ...props }) => {
	useEffect(() => {
		const { host, protocol, pathname, search } = window.location;
		if (host.endsWith(config.GCP_APP_SPOT)) {
			const newHost = host.toLowerCase().startsWith("stage") ? config.STAGE_ENDPOINT : config.PRODUCTION_ENDPOINT;
			window.location.assign(`${protocol}//${newHost}${pathname}${search}`);
		}

		if (host.startsWith('www.')) {
			if (host.endsWith(config.STAGE_ENDPOINT)) {
				window.location.assign(`${protocol}//${config.STAGE_ENDPOINT}${pathname}${search}`);
			} else if (host.endsWith(config.PRODUCTION_ENDPOINT)) {
				window.location.assign(`${protocol}//${config.PRODUCTION_ENDPOINT}${pathname}${search}`);
			}
		}

		const TRACKING_ID = "G-96GQ9N7V9X";
		ReactGA.initialize(TRACKING_ID);

		history.listen((location, action) => {
			// clear alert on location change // PERSISTENT ERRORS MUST STAY UNLESS stated otherwise on the other page
			props.clearError();
			ReactGA.send({ hitType: "pageview", page: location.pathname });
		});

		if (props.loggedIn && !userActions.verifyLastActionTimeStamp()) {
			history.push(config.clientUrls.LOGIN);
		}

		// @ATTENTION: DISABLING ESLING WARNINGS!
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>
		<Router history={history}>
			<CacheBuster>
				<ToastContainer />
				<Suspense fallback={<span>Loading...</span>}>
					<FreezeScreenLoader loading={loadingAPI.loading} />
					<FooterLayout>
						{props.loggedIn && <IddleTimer />}
						<Content />
					</FooterLayout>
				</Suspense>
			</CacheBuster>
		</Router>
	</>;
}

function mapState(state) {
	const { alert, loadingAPI } = state;
	const { loggedIn } = state.authentication;
	return { alert, loadingAPI, loggedIn };
}

const actionCreators = {
	clearError: alertActions.clearError,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
