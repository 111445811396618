import config from "_configs";
import { authHeader, handleResponse, handleResponseCompanyFallback } from "_helpers";

export const bookkeepingService = {
	qboConnect,
	qboSignIn,
	qboCallback,
	qboSignInCallback,
	qboDisconnect,
	qboDisconnectByRealmId,

	xeroConnect,
	xeroSignIn,
	xeroCallback,
	xeroSignInCallback,
	xeroDisconnect,

	getCompanies,
	getCompanyDetails,
	makeCompanyDefault,

	createCompany,
	getDefaultOrCreateCompany,

	getAccountList,

	deleteCompany,

};

const POST_JSON_HEADER = { "Content-Type": "application/json" };

function qboConnect(companyId) {
	return connect("qbo_manager", companyId);
}

function qboSignIn() {
	return signIn("qbo_manager");
}

function qboCallback(url) {
	return callback("qbo_manager", url);
}

function qboSignInCallback(url) {
	return signinCallback("qbo_manager", url);
}

function qboDisconnect(companyId) {
	return disconnect("qbo_manager", companyId);
}

function xeroConnect(companyId) {
	return connect("xero_manager", companyId);
}

function xeroSignIn() {
	return signIn("xero_manager");
}

function xeroCallback(url) {
	return callback("xero_manager", url);
}

function xeroSignInCallback(url) {
	return signinCallback("xero_manager", url);
}

function xeroDisconnect(companyId) {
	return disconnect("xero_manager", companyId);
}

function connect(manager_type, companyId) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/${manager_type}/${companyId}/auth_url`,
		requestOptions
	).then(handleResponse);
}

function signIn(manager_type) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/${manager_type}/auth/signin_url`,
		requestOptions
	).then(handleResponse);
}

function callback(manager_type, url) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ url }),
	};
	return fetch(
		`${config.apiUrl}/${manager_type}/callback`,
		requestOptions
	).then(handleResponse);
}

function signinCallback(manager_type, url) {
	const requestOptions = {
		method: "POST",
		headers: { ...authHeader(), ...POST_JSON_HEADER },
		credentials: 'include',
		body: JSON.stringify({ url }),
	};
	return fetch(
		`${config.apiUrl}/${manager_type}/signin_callback`,
		requestOptions
	).then(handleResponse);
}

function disconnect(manager_type, companyId) {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(
		`${config.apiUrl}/${manager_type}/${companyId}/disconnect`,
		requestOptions
	).then(handleResponse);
}

function qboDisconnectByRealmId(realmId) {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	const manager_type = "qbo_manager";
	return fetch(
		`${config.apiUrl}/${manager_type}/disconnect_by_realm/${realmId}`,
		requestOptions
	).then(handleResponse);
}

function getCompanyDetails(company_id) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/companies/${company_entry}`,
		requestOptions
	)
		.then(handleResponse)
		.then((res) => res.company);
}

function createCompany(isInit = false) {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	const companyType = isInit ? "init" : "new";
	return fetch(
		`${config.apiUrl}/bookkeeping/companies/${companyType}`,
		requestOptions
	)
		.then(handleResponse)
		.then((res) => res.companyId);
}

function getDefaultOrCreateCompany() {
	return createCompany(true).catch((error) => {
		if (error && error.companyId) {
			return error.companyId;
		}

		return Promise.reject(error);
	});
}

function getCompanies() {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	return fetch(`${config.apiUrl}/bookkeeping/companies`, requestOptions).then(
		handleResponse
	);
}

function makeCompanyDefault(company_id) {
	const requestOptions = {
		method: "POST",
		headers: authHeader(),
		credentials: 'include',
	};
	// default behavior **is not supported** for this API request.
	return fetch(
		`${config.apiUrl}/bookkeeping/companies/${company_id}/set_default`,
		requestOptions
	).then(handleResponse);
}

function deleteCompany(company_id) {
	const requestOptions = {
		method: "DELETE",
		headers: authHeader(),
		credentials: 'include',
	};
	// default behavior **is not supported** for this API request.
	return fetch(
		`${config.apiUrl}/bookkeeping/companies/${company_id}/delete`,
		requestOptions
	).then(handleResponse);
}

function getAccountList(company_id) {
	const requestOptions = {
		method: "GET",
		headers: authHeader(),
		credentials: 'include',
	};
	const company_entry = company_id ? company_id : "default";
	return fetch(
		`${config.apiUrl}/bookkeeping/${company_entry}/accounts/list`,
		requestOptions
	).then(handleResponseCompanyFallback(company_id));
}
