import React from "react";
import { Container, H1, P1 } from "_components/Base";
import { CurrencySelector } from "./CurrencySelector";

export const PriceCardPanel = ({ children, title, curCurrency, currencies, onCurrencyChange, ...props }) => {
    return (
        <Container>
            <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center h-center">
                <H1>{title}</H1>
                <CurrencySelector
                    curCurrency={curCurrency}
                    currencies={currencies}
                    onCurrencyChange={onCurrencyChange}
                />
                <P1 className="mt-4" style={{ maxWidth: "796px" }}>
                    We have created three affordable pricing tiers,
                    which differ in the number of companies you can connect.
                    All of the current and future basic modules will
                    be included in your subscription.
                </P1>
            </div>
            <div className="container-fluid">
                {/* <div className="card-deck text-center"> */}
                <div id="price-card-panel" className="d-flex h-center ">
                    {children}
                </div>
            </div>
        </Container>
    );
}