/**
 * This actions must not get data that will be stored in redux or out of component it uses it
 */
import { adminService } from "_services";
import { alertActions, loadingAPIActions } from ".";

const lockUsers = trueRespondingFunctionGenerator(adminService.lockUsers);
const unlockUsers = trueRespondingFunctionGenerator(adminService.unlockUsers);
const convertToDevAccount = trueRespondingFunctionGenerator(adminService.convertToDevAccount);
const giveDiscount = trueRespondingFunctionGenerator(adminService.giveDiscount);
const sendEmail = trueRespondingFunctionGenerator(adminService.sendEmail);


export const adminActions = {
    getUsers,
    lockUsers,
    unlockUsers,
    convertToDevAccount,
    giveDiscount,
    sendEmail,
    editPlan,
    listPlans,
};

function trueRespondingFunctionGenerator(func) {
    return function (...args) {
        return (dispatch) => {
            dispatch(loadingAPIActions.startLoadingAPI());
            return func(...args).then(
                (response) => {
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    dispatch(alertActions.success(response.message));
                    return true;
                },
                (error) => {
                    dispatch(alertActions.alert(error));
                    dispatch(loadingAPIActions.stopLoadingAPI());
                    throw error;
                }
            );
        };

    }
}

function getUsers(filter) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return adminService.getUsers(filter).then(
            (response) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return response.users;
            },
            (error) => {
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function editPlan(planId, data) {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return adminService.editPlan(planId, data).then(
            (response) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                dispatch(alertActions.success(response.message));
                return response.data;
            },
            (error) => {
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}

function listPlans() {
    return (dispatch) => {
        dispatch(loadingAPIActions.startLoadingAPI());
        return adminService.listPlans().then(
            ({ result }) => {
                dispatch(loadingAPIActions.stopLoadingAPI());
                return result;
            },
            (error) => {
                dispatch(alertActions.alert(error));
                dispatch(loadingAPIActions.stopLoadingAPI());
                throw error;
            }
        );
    };
}
