import React from "react";
import ReactDOM from "react-dom";

import { Confirm } from "./Confirm";
import $ from "jquery";

export const confirm = function (message, options) {
	var cleanup, component, props, wrapper;
	if (options == null) {
		options = {};
	}
	props = $.extend(
		{
			message: message,
		},
		options
	);
	wrapper = document
		.getElementById("confirmation_modal")
		.appendChild(document.createElement("div"));
	component = ReactDOM.render(<Confirm {...props} />, wrapper);
	cleanup = function () {
		ReactDOM.unmountComponentAtNode(wrapper);
		return setTimeout(function () {
			return wrapper.remove();
		});
	};
	return component.promise
		.always(cleanup)
		.promise()
		.then((obj) => {
			// hack to avoid jquery unhandled promise rejection (can't spot where is it)
			if (obj.success) {
				return Promise.resolve();
			} else {
				return Promise.reject("Cancellation");
			}
		});
};
