export const alertConstants = {
	SUCCESS: "ALERT_SUCCESS",
	ERROR: "ALERT_ERROR",
	PERSISTENT_ERROR: "ALERT_PERSISTENT_ERROR",
	WARNING: "ALERT_WARNING",
	CLEAR: "ALERT_CLEAR",

	CLEAR_SUCCESS: "CLEAR_SUCCESS",
	CLEAR_WARNING: "CLEAR_WARNING",
	CLEAR_PERSISTENT_ERROR: "CLEAR_PERSISTENT_ERROR",
	CLEAR_ERROR: "CLEAR_ERROR",
	CLEAR_ALL: "CLEAR_ALL_ALERTS"
};
